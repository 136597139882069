import { Component, Vue } from "vue-property-decorator";
import { InjectModule } from "@/decorators/InjectModule";
import Dialog from "@/store/module/modal/Dialog";
import Confirm from "@/store/module/modal/Confirm";
import Alert from "@/store/module/modal/Alert";
import User from "@/store/module/User";
import Loading from "@/store/module/modal/Loading";

@Component
export default class Base extends Vue {
  @InjectModule(Dialog)
  public dialog$!: Dialog;

  @InjectModule(Confirm)
  public confirm$!: Confirm;

  @InjectModule(Alert)
  public alert$!: Alert;

  @InjectModule(Loading)
  public loading$!: Loading;

  @InjectModule(User)
  public user$!: User;

  public isPopUpComponent = false;

  public beforeDestroy(): void {
    if (this.isPopUpComponent) return;

    if (this.dialog$.isOpen && this.dialog$.isAutoClose) {
      this.dialog$.close();
    }
    if (this.confirm$.isOpen && this.confirm$.isAutoClose) {
      this.confirm$.close();
    }
    if (this.alert$.isOpen && this.alert$.isAutoClose) {
      this.alert$.close();
    }
  }

  public openRequestFailAlert(): void {
    this.alert$.open({
      text:
        "해당 요청을 처리하는데 문제가 발생했습니다.\n잠시 후에 다시 시도해주시기 바랍니다."
    });
  }
}
