import { RouterMenuName } from "@/enum/RouterMenuName";
import { menu } from "@/router/menu";
import { HeaderClassType } from "@/enum/HeaderClassType";
import { LayoutType } from "@/enum/LayoutType";
import { RouteConfig } from "vue-router";

export const payment: Array<RouteConfig> = [
  {
    path: "/payment",
    name: RouterMenuName.Payment,
    component: menu.payment.main,
    meta: {
      headerClass: `${HeaderClassType.My}`,
      layout: LayoutType.FormLayout,
      guard: {
        login: true
      }
    }
  },
  {
    path: "/payment/complete/:paymentId",
    name: RouterMenuName.PaymentComplete,
    component: menu.payment.complete,
    meta: {
      headerClass: `${HeaderClassType.My}`,
      layout: LayoutType.FormLayout,
      guard: {
        login: true
      }
    }
  }
];
