import Vue, { AsyncComponent, CreateElement } from "vue";
import { router } from "@/router";
import { storeOptions } from "@/store";
import VueRouter from "vue-router";
import Vuex, { Store } from "vuex";
import { connector } from "@/service/connector";
import { config } from "@/config/axios";
import { filter } from "@/filter";
import { getCookie } from "@/util/CookieUtil";
import { CookieType } from "@/enum/CookieType";
import UserModel from "@/service/model/user/UserModel";
import { userService } from "@/service/api/UserService";
import { Observable, of } from "rxjs";
import { finalize } from "rxjs/operators";
import VueClipboard from "vue-clipboard2";
import { Chart, registerables } from "chart.js";
import plugin from "chartjs-plugin-datalabels";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import { getModule } from "vuex-module-decorators";
import Organization from "@/store/module/Organization";
import vClickOutside from "v-click-outside";
import * as Sentry from "@sentry/vue";

class Application {
  public vueOptions: Record<string, object> = {};

  constructor() {
    Vue.config.productionTip = false;
    this.enableVueX();
    this.enableRouter();
    this.enableAxios();
    this.enableFilter();
    this.enableClipboard();
    this.enableChartJs();
    this.enableCKEditor();
    this.enableSentry();
    this.enableClickOutside();
  }

  private enableRouter() {
    Vue.use(VueRouter);
    this.vueOptions.router = router;
  }

  private enableVueX() {
    Vue.use(Vuex);
    this.vueOptions.store = new Vuex.Store(storeOptions);
    getModule(Organization, this.vueOptions.store as Store<never>);
  }

  private enableAxios() {
    connector.setConfig(config);
  }

  private enableFilter() {
    this.vueOptions.filters = filter;
  }

  private enableCKEditor() {
    Vue.use(CKEditor);
  }

  private enableSentry() {
    Sentry.init({
      Vue,
      dsn: process.env.VUE_APP_SENTRY_DSN,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router)
        }),
        new Sentry.Replay()
      ],

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost:8080",
        process.env.VUE_APP_MAIN_URL,
        process.env.VUE_APP_END_POINT
      ],
      hooks: ["mount", "update", "destroy"],
      release: process.env.BUILD_VERSION,
      environment: process.env.BUILD_VERSION,

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.05,

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.01
      // replaysOnErrorSampleRate: 1.0 // error replay sample 비율
    });
  }

  private enableClickOutside(): void {
    Vue.use(vClickOutside);
  }

  private userLogin(): Observable<UserModel | null> {
    const token: string | undefined = getCookie(CookieType.AccessToken);
    if (token) {
      return userService.retrieveUser();
    }
    return of(null);
  }

  private enableClipboard(): void {
    Vue.use(VueClipboard);
  }

  private enableChartJs(): void {
    Chart.register(...registerables, plugin);
  }

  /**
   * 토큰이 있을경우 로그인 후 rendering
   */
  public bootstrap() {
    const app: AsyncComponent = () =>
      import(/* webpackChunkName: "App" */ "@/App.vue");
    this.vueOptions.render = (h: CreateElement) => h(app);
    const vue = new Vue(this.vueOptions);
    this.userLogin()
      .pipe(
        finalize(() => {
          vue.$store.dispatch("organization/initOrganization");
          vue.$mount("#app");
        })
      )
      .subscribe((user: UserModel | null) => {
        if (user) {
          vue.$store.commit("user/setUser", user);
        }
      });
  }
}

const application = new Application();
application.bootstrap();
