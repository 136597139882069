import { menu } from "@/router/menu";
import { HeaderClassType } from "@/enum/HeaderClassType";
import { LayoutType } from "@/enum/LayoutType";
import { RouteConfig } from "vue-router";
import RouterMeta from "@/router/model/RouterMeta";
import { RouterMenuName } from "@/enum/RouterMenuName";

const meta: RouterMeta = {
  headerClass: `${HeaderClassType.My}`,
  layout: LayoutType.FormLayout
};

export const reference: RouteConfig[] = [
  {
    path: "/reference",
    name: RouterMenuName.Reference,
    component: menu.reference.main,
    meta
  },
  {
    path: "/reference/:referenceId",
    name: RouterMenuName.ReferenceDetail,
    component: menu.reference.detail,
    meta
  }
];
