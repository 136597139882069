import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import OrganizationModel from "@/service/model/organization/OrganizationModel";
import { organizationService } from "@/service/api/OrganizationService";
import { communityService } from "@/service/api/CommunityService";
import CategoryModel from "@/service/model/community/CategoryModel";
import { CommunityCategoryType } from "@/enum/community/CommunityCategoryType";

@Module({ namespaced: true, name: "organization" })
export default class Organization extends VuexModule {
  public organization = new OrganizationModel();
  public categories: CategoryModel[] = [];

  @Mutation
  public setOrganization(organization: OrganizationModel): void {
    this.organization = organization;
  }

  @Mutation
  public setCategory(): void {
    communityService
      .retrieveCommunityCategoryList(this.organization.id)
      .subscribe((categories: CategoryModel[]) => {
        this.categories = categories.sort((a, b) => a.order - b.order);
      });
  }

  @Action
  public initOrganization(handler?: (isSuccess: boolean) => void): void {
    const path: string[] = window.location.hostname.split(".");
    if (
      "localhost" !== path[0] &&
      !/^https:\/\/(www|stage|dev)?\.?codepresso\.kr$/.test(
        window.location.origin
      )
    ) {
      organizationService
        .retrieveByHostname(window.location.hostname.replace("www.", ""))
        .subscribe(
          (organization: OrganizationModel) => {
            this.context.commit("setOrganization", organization);
            this.context.commit("setCategory");
            if (handler) handler(true);
          },
          () => {
            this.context.commit("setCategory");
            if (handler) handler(false);
            // window.location.href = process.env.VUE_APP_MAIN_URL;
          }
        );
    } else {
      this.context.commit("setCategory");
    }
  }

  public get isPrivateOrganizationPage(): boolean {
    return this.organization.id !== 1;
  }

  public get providerIdText(): string {
    if (this.organization.id === 83) return "사번";
    else return "고유번호";
  }

  public get noticeCategoryId(): number | null {
    const index = this.categories.findIndex(
      category => category.categoryType === CommunityCategoryType.NOTICE
    );
    return index === -1 ? null : this.categories[index].id;
  }
}
