import { createDecorator } from "vue-class-component";
import { getModule, VuexModule } from "vuex-module-decorators";

/* eslint-disable  @typescript-eslint/no-explicit-any */
export function InjectModule<M extends VuexModule>(
  moduleClass: new (...args: any[]) => M
) {
  return createDecorator((options, key) => {
    options.data = (key: string) => ({
      [key]: null
    });
    const originalMethod = options.created;

    options.created = function wrapperMethod(...args) {
      const module: any = getModule(moduleClass, (this as Vue).$store);
      Reflect.set(this, key, module);
      if (originalMethod) {
        originalMethod.call(this, ...args);
      }
    };
  });
}
