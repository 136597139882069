import { createModel } from "@/util/ModelUtil";
import { LectureType } from "@/enum/LectureType";

export default class MyLectureModel {
  constructor(
    public id: number = 0,
    public userId: number = 0,
    public lectureId: number = 0,
    public lastSubChapterId: number = 0,
    public progress: number = 0,
    public completedFlag: boolean = false,
    public lectureTitle: string = "",
    public lectureType: LectureType = LectureType.ELearning,
    public courseSubTitle: string = "",
    public cardImageUrl: string = "",
    public startedAt: number = new Date().getTime(),
    public endedAt: number = new Date().getTime(),
    public createdAt: number = new Date().getTime(),
    public updatedAt: number = new Date().getTime(),
    public myChapterList: MyChapter[] = []
  ) {}

  public static create(myLecture?: MyLecture): MyLectureModel {
    return createModel<MyLectureModel>(MyLectureModel, myLecture);
  }
}
