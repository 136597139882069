import { menu } from "@/router/menu";
import { HeaderClassType } from "@/enum/HeaderClassType";
import { LayoutType } from "@/enum/LayoutType";
import { RouteConfig } from "vue-router";
import RouterMeta from "@/router/model/RouterMeta";
import { RouterMenuName } from "@/enum/RouterMenuName";

const meta: RouterMeta = {
  headerClass: HeaderClassType.My,
  layout: LayoutType.NavigationLayout
};

export const blog: RouteConfig[] = [
  {
    path: "/blog",
    name: RouterMenuName.BlogList,
    component: menu.blog.main,
    meta
  },
  {
    path: "/blog/:blogContentId",
    name: RouterMenuName.BlogDetail,
    component: menu.blog.detail,
    meta
  }
];
