export enum SubscribeType {
  BASIC = "BASIC",
  STANDARD = "STANDARD",
  PRO = "PRO"
}

export enum SubscribeTypeText {
  BASIC = "베이직",
  STANDARD = "스탠다드",
  PRO = "프로"
}

export enum SubscribeTypeClassName {
  BASIC = "basic",
  STANDARD = "standard",
  PRO = "pro"
}
