import moment from "moment";

export default function(
  date: Date | number | null | undefined,
  emptyText = "",
  format = "YYYY.MM.DD"
): string {
  if (!date) {
    return emptyText;
  }
  return moment(date, "YYYY-MM-DD HH:mm").format(format);
}
