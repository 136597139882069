export enum PaymentErrorType {
  NONE = "NONE",
  USER_CANCEL = "USER_CANCEL",
  PAY_PROCESS_CANCELED = "PAY_PROCESS_CANCELED",
  PAY_PROCESS_ABORTED = "PAY_PROCESS_ABORTED",
  REJECT_CARD_COMPANY = "REJECT_CARD_COMPANY",
  INVALID_BIRTH_DAY_FORMAT = "INVALID_BIRTH_DAY_FORMAT",
  INVALID_CASH_RECEIPT_INFO = "INVALID_CASH_RECEIPT_INFO",
  EXCEED_MAX_VALID_HOURS = "EXCEED_MAX_VALID_HOURS",
  ALREADY_COMPLETED_PAYMENT = "ALREADY_COMPLETED_PAYMENT",
  ALREADY_PROCESSED_PAYMENT = "ALREADY_PROCESSED_PAYMENT",
  ALREADY_CANCELED_PAYMENT = "ALREADY_CANCELED_PAYMENT",
  INVALID_ACCOUNT_NOT_CORRECT_BANK = "INVALID_ACCOUNT_NOT_CORRECT_BANK",
  INVALID_CARD_NUMBER = "INVALID_CARD_NUMBER",
  NOT_SUPPORTED_USD = "NOT_SUPPORTED_USD",
  NOT_SUPPORTED_MONTHLY_INSTALLMENT_PLAN = "NOT_SUPPORTED_MONTHLY_INSTALLMENT_PLAN",
  ALREADY_REFUND_PAYMENT = "ALREADY_REFUND_PAYMENT",
  ALREADY_REFUNDING_PAYMENT = "ALREADY_REFUNDING_PAYMENT",
  INVALID_REFUND_ACCOUNT_NUMBER = "INVALID_REFUND_ACCOUNT_NUMBER",
  INVALID_REFUND_ACCOUNT_INFO = "INVALID_REFUND_ACCOUNT_INFO",
  NOT_CANCELABLE_PAYMENT = "NOT_CANCELABLE_PAYMENT",
  NOT_CANCELABLE_AMOUNT = "NOT_CANCELABLE_AMOUNT",
  EXCEED_MAX_REFUND_AMOUNT = "EXCEED_MAX_REFUND_AMOUNT",
  EXCEED_MAX_REFUND_DUE = "EXCEED_MAX_REFUND_DUE",
  NOT_AVAILABLE_BANK = "NOT_AVAILABLE_BANK",
  NOT_ALLOWED_REFUND_BANK = "NOT_ALLOWED_REFUND_BANK",
  NOT_ALLOWED_CHANGING_ACCOUNT = "NOT_ALLOWED_CHANGING_ACCOUNT",
  NOT_FOUND_PAYMENT = "NOT_FOUND_PAYMENT",
  NOT_FOUND_ACCOUNT = "NOT_FOUND_ACCOUNT",
  NOT_FOUND_PAYMENT_TO_CONFIRM = "NOT_FOUND_PAYMENT_TO_CONFIRM",
  NOT_FOUND_PAYMENT_SESSION = "NOT_FOUND_PAYMENT_SESSION",
  FAILED_REFUND_PROCESS = "FAILED_REFUND_PROCESS",

  INVALID_REQUEST = "INVALID_REQUEST",
  INVALID_CARD_COMPANY = "INVALID_CARD_COMPANY",
  INVALID_BANK = "INVALID_BANK",
  INVALID_CARD = "INVALID_CARD",
  INVALID_CARD_EXPIRATION = "INVALID_CARD_EXPIRATION",
  INVALID_CARD_PASSWORD = "INVALID_CARD_PASSWORD",
  INVALID_CARD_IDENTITY = "INVALID_CARD_IDENTITY",
  INVALID_STOPPED_CARD = "INVALID_STOPPED_CARD",
  INVALID_CARD_INSTALLMENT_AMOUNT = "INVALID_CARD_INSTALLMENT_AMOUNT",
  INVALID_DATE = "INVALID_DATE",
  NOT_MATCHES_REFUNDABLE_AMOUNT = "NOT_MATCHES_REFUNDABLE_AMOUNT",
  EXCEED_MAX_PAYMENT_AMOUNT = "EXCEED_MAX_PAYMENT_AMOUNT",
  EXCEED_MAX_DAILY_PAYMENT_COUNT = "EXCEED_MAX_DAILY_PAYMENT_COUNT",
  EXCEED_UNKNOWN_CASE = "EXCEED_UNKNOWN_CASE",
  EXCEED_MAX_AMOUNT = "EXCEED_MAX_AMOUNT",
  EXCEED_MAX_ONE_TIME_WITHDRAW_AMOUNT = "EXCEED_MAX_ONE_TIME_WITHDRAW_AMOUNT",
  EXCEED_MAX_ONE_DAY_WITHDRAW_AMOUNT = "EXCEED_MAX_ONE_DAY_WITHDRAW_AMOUNT",
  INVALID_AUTHORIZE_AUTH_TYPE_GIFT_CERTIFICATE = "INVALID_AUTHORIZE_AUTH_TYPE_GIFT_CERTIFICATE",
  INVALID_AUTHORIZE_AUTH = "INVALID_AUTHORIZE_AUTH",
  INVALID_IDENTIFICATION_TYPE = "INVALID_IDENTIFICATION_TYPE",
  INVALID_VIRTUAL_ACCOUNT_TYPE = "INVALID_VIRTUAL_ACCOUNT_TYPE",
  INVALID_EMAIL = "INVALID_EMAIL",
  INVALID_EASY_PAY = "INVALID_EASY_PAY",
  INVALID_BUSINESS_NUMBER = "INVALID_BUSINESS_NUMBER",
  INVALID_ACCOUNT_NUMBER = "INVALID_ACCOUNT_NUMBER",
  INVALID_ACCOUNT_NUMBER_OR_UNAVAILABLE = "INVALID_ACCOUNT_NUMBER_OR_UNAVAILABLE",
  INVALID_REJECT_CARD = "INVALID_REJECT_CARD",
  NOT_SUPPORTED_PROCESS = "NOT_SUPPORTED_PROCESS",
  NOT_REGISTERED_BUSINESS = "NOT_REGISTERED_BUSINESS",
  NOT_ALLOWED_POINT_USE = "NOT_ALLOWED_POINT_USE",
  NOT_ALLOWED_INSTALLMENT_BELOW_AMOUNT = "NOT_ALLOWED_INSTALLMENT_BELOW_AMOUNT",
  EXCEED_MAX_DUE_DATE = "EXCEED_MAX_DUE_DATE",
  EXCEED_MAX_CARD_INSTALLMENT_PLAN = "EXCEED_MAX_CARD_INSTALLMENT_PLAN",
  EXCEED_MIN_CARD_INSTALLMENT_PLAN = "EXCEED_MIN_CARD_INSTALLMENT_PLAN",
  BELOW_MINIMUM_AMOUNT = "BELOW_MINIMUM_AMOUNT",
  BELOW_ZERO_AMOUNT = "BELOW_ZERO_AMOUNT",
  ACCOUNT_OWNER_CHECK_FAILED = "ACCOUNT_OWNER_CHECK_FAILED",
  NOT_MATCH_PAYEE_NAME = "NOT_MATCH_PAYEE_NAME",
  ALREADY_EXIST_SUBMALL = "ALREADY_EXIST_SUBMALL",
  ALREADY_CLOSE_ACCOUNT = "ALREADY_CLOSE_ACCOUNT",
  INVALID_REFUND_AMOUNT = "INVALID_REFUND_AMOUNT",
  EXCEED_MAX_AUTH_COUNT = "EXCEED_MAX_AUTH_COUNT",
  INVALID_PASSWORD = "INVALID_PASSWORD",
  REJECT_ACCOUNT_PAYMENT = "REJECT_ACCOUNT_PAYMENT",
  COMMON_ERROR = "COMMON_ERROR"
}
