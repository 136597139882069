import VueRouter, { Route, RouteConfig } from "vue-router";
import { RouterMenuName } from "@/enum/RouterMenuName";
import { menu } from "@/router/menu";
import { HeaderClassType } from "@/enum/HeaderClassType";
import { LayoutType } from "@/enum/LayoutType";
import signUp from "@/router/signUp";
import { myPage } from "@/router/my";
import auth from "@/router/auth";
import Home from "@/views/home/Home.vue";
import { lecturePage } from "@/router/lecture";
import { specialTrackPage } from "@/router/specialTrack";
import { subscribePage } from "@/router/subscribe";
import { organizationPage } from "@/router/organization";
import { organizationConsultingPage } from "@/router/organizationConsulting";
import { course } from "@/router/course";
import { payment } from "@/router/payment";
import { community } from "@/router/community";
import { blog } from "@/router/blog";
import { customerCenterPage } from "@/router/customerCenter";
import { reference } from "@/router/reference";
import { RouteMeta } from "vue-router/types/router";

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: RouterMenuName.Home,
    component: Home,
    meta: {
      headerClass: HeaderClassType.My,
      layout: LayoutType.NavigationLayout
    }
  },
  {
    path: "/signIn",
    name: RouterMenuName.SignIn,
    component: menu.signIn.main,
    meta: {
      headerClass: HeaderClassType.My,
      layout: LayoutType.FormLayout,
      guard: {
        login: false
      }
    }
  },
  {
    path: "/signIn/fail",
    name: RouterMenuName.SignInFail,
    component: menu.signIn.fail,
    meta: {
      headerClass: HeaderClassType.My,
      layout: LayoutType.FormLayout,
      guard: {
        login: false
      }
    }
  },
  {
    path: "/passwordChange",
    name: RouterMenuName.MyPasswordChange,
    component: menu.my.passwordChange.main,
    meta: {
      headerClass: `${HeaderClassType.My}`,
      layout: LayoutType.FormLayout,
      guard: {
        login: true
      }
    }
  },
  ...course,
  ...signUp,
  myPage,
  ...auth,
  ...lecturePage,
  ...specialTrackPage,
  ...subscribePage,
  ...organizationPage,
  ...organizationConsultingPage,
  ...payment,
  ...blog,
  ...reference,
  customerCenterPage,
  {
    path: "/cart",
    name: RouterMenuName.Cart,
    component: menu.cart.main,
    meta: {
      headerClass: `${HeaderClassType.My}`,
      layout: LayoutType.FormLayout,
      guard: {
        login: true
      }
    }
  },
  {
    path: "/ai",
    name: RouterMenuName.AiHubStaticListAi,
    component: menu.aiHub.aiHubList,
    meta: {
      headerClass: `${HeaderClassType.My} hover`,
      layout: LayoutType.FormLayout
    }
  },
  {
    path: "/da",
    name: RouterMenuName.AiHubStaticListDa,
    component: menu.aiHub.aiHubList,
    meta: {
      headerClass: `${HeaderClassType.My} hover`,
      layout: LayoutType.FormLayout
    }
  },
  {
    path: "/ai/:index",
    name: RouterMenuName.AiHubStaticDetailAi,
    component: menu.aiHub.aiHubDetail,
    meta: {
      headerClass: `${HeaderClassType.My} hover`,
      layout: LayoutType.FormLayout
    }
  },
  ...community,
  {
    path: "/da/:index",
    name: RouterMenuName.AiHubStaticDetailDa,
    component: menu.aiHub.aiHubDetail,
    meta: {
      headerClass: `${HeaderClassType.My} hover`,
      layout: LayoutType.FormLayout
    }
  },
  {
    path: "*",
    redirect: { name: RouterMenuName.Home }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to: Route) => {
    if (
      Object.prototype.hasOwnProperty.call(to.meta, "scroll") &&
      !(to.meta as RouteMeta).scroll
    ) {
      return null;
    }
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  router.app.$store.commit("history/addHistory", to);

  router.fromRouterName = from.name as string;
  router.fromNeedLogin = !!from.meta?.guard?.login;
  next();
});

export { router, routes };
