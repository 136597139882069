import { createModel } from "@/util/ModelUtil";
import { UserType } from "@/enum/UserType";

export default class PostCommentModel {
  constructor(
    public id: number = 0,
    public userId: number = 0,
    public postId: number = 0,
    public content: string = "",
    public userNickName: string = "",
    public userProfileImageUrl: string = "",
    public exposureFlag: boolean = false,
    public userType: UserType = UserType.NORMAL,
    public createdAt: number = new Date().getTime(),
    public updatedAt: number = new Date().getTime(),
    public isLikeComment: boolean = false,
    public questionPostId: number = 0,
    public likeCount: number = 0,
    public replyCount: number = 0,

    public isShowReplyEditor: boolean = false,
    public isShowReply: boolean = false,
    public bestCommentIndex: number = -1
  ) {}

  public static create(comment?: PostComment): PostCommentModel {
    return createModel<PostCommentModel>(PostCommentModel, comment);
  }
}
