import { createModel } from "@/util/ModelUtil";
import { LectureType } from "@/enum/LectureType";

export default class LectureModel {
  constructor(
    public id: number = -1,
    public courseId: number = 0,
    public organizationId: number = 0,
    public organizationName: string = "",
    public title: string = "",
    public type: string = LectureType.ELearning,
    public startedAt: number = 0,
    public endedAt: number = 0,
    public subscribeType: string = "",
    public createdAt: number = new Date().getTime(),
    public updatedAt: number = new Date().getTime(),
    public chapterList: Chapter[] = [],
    public completionRate: number = 80
  ) {}

  public static create(lecture?: Lecture): LectureModel {
    return createModel<LectureModel>(LectureModel, lecture);
  }
}
