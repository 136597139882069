import { createModel } from "@/util/ModelUtil";
import { UserType } from "@/enum/UserType";
import { UserGender } from "@/enum/UserGender";
import { isEmptyString } from "@/util/StringUtil";
import { UserStatus } from "@/enum/UserStatus";

export default class UserModel {
  constructor(
    public id: number = -1,
    public email: string = "",
    public emailAuthFlag: boolean = false,
    public name: string = "",
    public nickName: string = "",
    public profileImageUrl: string = "",
    public introduction: string = "",
    public organizationId: number = 0,
    public type: UserType = UserType.NORMAL,
    public birthday: string = "",
    public gender: UserGender = UserGender.M,
    public organizationName: string = "",
    public department: string = "",
    public phone: string = "",
    public address: string = "",
    public emailMarketingTermsFlag: boolean = false,
    public smsMarketingTermsFlag: boolean = false,
    public status: UserStatus = UserStatus.NORMAL,
    public encryptPasswordFlag: boolean = false
  ) {}

  public static create(user?: User): UserModel {
    if (user) {
      if (isEmptyString(user.birthday)) user.birthday = "";
      if (isEmptyString(user.phone)) user.phone = "";
      if (isEmptyString(user.organizationName)) user.organizationName = "";
      if (isEmptyString(user.department)) user.department = "";
    }
    return createModel<UserModel>(UserModel, user);
  }
}
