import { RouterMenuName } from "@/enum/RouterMenuName";
import { menu } from "@/router/menu";
import { HeaderClassType } from "@/enum/HeaderClassType";
import { LayoutType } from "@/enum/LayoutType";
import { Route, RouteConfig } from "vue-router";
import RouterMeta from "@/router/model/RouterMeta";
const meta = (scroll: boolean): RouterMeta => {
  return {
    headerClass: `${HeaderClassType.My}`,
    layout: LayoutType.FormLayout,
    scroll
  };
};

export const community: Array<RouteConfig> = [
  {
    path: "/community",
    component: menu.community.main,
    name: RouterMenuName.Community,
    meta: meta(false)
  },
  {
    path: "/community/write",
    component: menu.community.write,
    name: RouterMenuName.CommunityWrite,
    meta: { ...meta(false), guard: { login: true } }
  },
  {
    path: "/community/:id",
    component: menu.community.detail,
    name: RouterMenuName.CommunityDetail,
    meta: meta(true),
    props: (route: Route) => {
      return { id: route.params.id };
    }
  }
];
