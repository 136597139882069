export enum SubChapterType {
  VideoAndContentAndCode = "VIDEO_AND_CONTENT_AND_CODE",
  VideoAndContentAndTestCode = "VIDEO_AND_CONTENT_AND_TESTCODE",
  ContentAndCode = "CONTENT_AND_CODE",
  ContentAndTestCode = "CONTENT_AND_TESTCODE",
  Content = "CONTENT",
  Video = "VIDEO",
  Test = "TEST",
  Survey = "SURVEY"
}
