import { AsyncComponent } from "vue";

type menuContent = { [key: string]: AsyncComponent };
// eslint-disable-next-line
export const menu: { [key: string]: menuContent | any } = {
  course: {
    main: () =>
      import(
        /* courseMain: "courseMain" */ "@/views/course/main/CourseMain.vue"
      ),
    detail: () =>
      import(
        /* webpackChunkName: "courseDetail" */ "@/views/course/detail/CourseDetail.vue"
      )
  },
  signIn: {
    main: () =>
      import(
        /* webpackChunkName: "signIn" */ "@/views/signIn/main/SignInMain.vue"
      ),
    fail: () =>
      import(
        /* webpackChunkName: "signIn" */ "@/views/signIn/fail/SignInFail.vue"
      )
  },
  signUp: {
    main: () =>
      import(
        /* webpackChunkName: "SignUpMain" */ "@/views/signUp/main/SignUpMain.vue"
      ),
    normal: () =>
      import(
        /* webpackChunkName: "SignUpNormal" */ "@/views/signUp/normal/SignUpNormal.vue"
      ),
    complete: () =>
      import(
        /* webpackChunkName: "SignUpComplete" */ "@/views/signUp/complete/SignUpComplete.vue"
      )
  },
  my: {
    course: {
      main: () =>
        import(
          /* webpackChunkName: "MyCourse" */ "@/views/my/course/main/MyCourse.vue"
        ),
      result: () =>
        import(
          /* webpackChunkName: "MyCourseResult" */ "@/views/my/course/result/MyCourseResult.vue"
        )
    },
    community: {
      main: () =>
        import(
          /* webpackChunkName: "MyCommunity" */ "@/views/my/community/main/MyCommunity.vue"
        )
    },
    memo: {
      main: () =>
        import(
          /* webpackChunkName: "MyMemo" */ "@/views/my/memo/main/MyMemo.vue"
        )
    },
    wishList: {
      main: () =>
        import(
          /* webpackChunkName: "MyWishList" */ "@/views/my/wishList/MyWishList.vue"
        )
    },
    coupon: {
      main: () =>
        import(
          /* webpackChunkName: "MyPaymentHistory" */ "@/views/my/coupon/main/MyCoupon.vue"
        )
    },
    payment: {
      main: () =>
        import(
          /* webpackChunkName: "MyPaymentHistory" */ "@/views/my/payment/main/MyPayment.vue"
        )
    },
    subscribe: {
      main: () =>
        import(
          /* webpackChunkName: "MySubscribeMain" */ "@/views/my/subscribe/MySubscribeMain.vue"
        )
    },
    notice: {
      main: () =>
        import(
          /* webpackChunkName: "MyNotice" */ "@/views/my/notice/main/MyNotice.vue"
        )
    },
    alarm: {
      main: () =>
        import(
          /* webpackChunkName: "MyAlarm" */ "@/views/my/alarm/main/MyAlarm.vue"
        )
    },
    profile: {
      main: () =>
        import(
          /* webpackChunkName: "MyProfile" */ "@/views/my/profile/main/MyProfile.vue"
        )
    },
    report: {
      main: () =>
        import(
          /* webpackChunkName: "MyReport" */ "@/views/my/report/parent/MyReportMain.vue"
        ),
      lecture: () =>
        import(
          /* webpackChunkName: "MyReportLecture" */ "@/views/my/report/lecture/MyReportLecture.vue"
        ),
      lectureDetail: () =>
        import(
          /* webpackChunkName: "MyReportLectureDetail" */ "@/views/my/report/lectureDetail/MyReportLectureDetail.vue"
        ),
      lectureSurvey: () =>
        import(
          /* webpackChunkName: "MyReportLectureSurvey" */ "@/views/my/report/lectureSurvey/MyReportLectureSurvey.vue"
        ),
      student: () =>
        import(
          /* webpackChunkName: "MyReportStudent" */ "@/views/my/report/student/MyReportStudent.vue"
        ),
      studentDetail: () =>
        import(
          /* webpackChunkName: "MyReportStudentDetail" */ "@/views/my/report/studentDetail/MyReportStudentDetail.vue"
        ),
      studentLectureDetail: () =>
        import(
          /* webpackChunkName: "MyReportStudentLectureDetail" */ "@/views/my/report/studentLectureDetail/MyReportStudentLectureDetail.vue"
        )
    },
    specialTrackReport: {
      main: () =>
        import(
          /* webpackChunkName: "MySpecialTrackReport" */ "@/views/my/specialTrackReport/main/MySpecialTrackReport.vue"
        ),
      detail: () =>
        import(
          /* webpackChunkName: "MySpecialTrackReportDetail" */ "@/views/my/specialTrackReport/detail/MySpecialTrackReportDetail.vue"
        )
    },
    lecturerQna: {
      main: () =>
        import(
          /* webpackChunkName: "MyLecturerQna" */ "@/views/my/lecturerQna/main/MyLecturerQna.vue"
        )
    },
    parent: () =>
      import(/* webpackChunkName: "MyMain" */ "@/views/my/parent/MyMain.vue"),
    passwordChange: {
      main: () =>
        import(
          /* webpackChunkName: "MyPasswordChange" */ "@/views/my/passwordChange/MyPasswordChangePassedNinetyDays.vue"
        )
    },
    ticket: {
      main: () =>
        import(
          /* webpackChunkName: "MyTicket" */ "@/views/my/ticket/MyTicketMain.vue"
        )
    },
    specialTrack: {
      main: () =>
        import(
          /* webpackChunkName: "MySpecialTrack" */ "@/views/my/specialTrack/MySpecialTrackMain.vue"
        )
    }
  },
  lecture: {
    main: () =>
      import(
        /* webpackChunkName: "LectureMain" */ "@/views/lecture/main/LectureMain.vue"
      ),
    codeEditor: () =>
      import(
        /* webpackChunkName: "LectureCodeEditor" */ "@/views/lecture/codeEditor/LectureCodeEditor.vue"
      ),
    editor: () =>
      import(
        /* webpackChunkName: "LectureEditor" */ "@/views/lecture/editor/LectureEditor.vue"
      )
  },
  auth: {
    user: {
      passwordChange: () =>
        import(
          /* webpackChunkName: "AuthUserPasswordChange" */ "@/views/auth/user/passwordChange/AuthUserPasswordChange.vue"
        ),
      email: () =>
        import(
          /* webpackChunkName: "AuthUserEmail" */ "@/views/auth/user/email/AuthUserEmail.vue"
        ),
      social: () =>
        import(
          /* webpackChunkName: "AuthUserSocial" */ "@/views/auth/user/social/AuthUserSocial.vue"
        ),
      organization: () =>
        import(
          /* webpackChunkName: "AuthUserSocial" */ "@/views/auth/organization/AuthOrganizationUser.vue"
        ),
      terms: () =>
        import(
          /* webpackChunkName: "AuthUserTerms" */ "@/views/auth/user/terms/AuthUserTerms.vue"
        )
    },
    payment: {
      main: () =>
        import(
          /*webpackChunkName: "AuthPayment"*/ "@/views/auth/payment/AuthPayment.vue"
        )
    }
  },
  specialTrack: {
    main: () =>
      import(
        /* webpackChunkName: "SpecialTrackMain" */ "@/views/specialTrack/main/SpecialTrackMain.vue"
      ),
    detail: () =>
      import(
        /* webpackChunkName: "SpecialTrackDetail" */ "@/views/specialTrack/detail/SpecialTrackDetail.vue"
      ),
    apply: {
      main: () =>
        import(
          /* webpackChunkName: "SpecialTrackApply" */ "@/views/specialTrack/apply/main/SpecialTrackApply.vue"
        ),
      complete: () =>
        import(
          /* webpackChunkName: "SpecialTrackApplyComplete" */ "@/views/specialTrack/apply/complete/SpecialTrackApplyComplete.vue"
        )
    }
  },
  cart: {
    main: () =>
      import(/*webpackChunkName: "Cart"*/ "@/views/cart/main/Cart.vue")
  },
  payment: {
    main: () =>
      import(
        /*webpackChunkName: "Payment"*/ "@/views/payment/main/Payment.vue"
      ),
    complete: () =>
      import(
        /*webpackChunkName: "PaymentComplete"*/ "@/views/payment/complete/PaymentComplete.vue"
      )
  },
  aiHub: {
    aiHubList: () =>
      import(/*webpackChunkName: "AiHubList"*/ "@/views/aiHub/AiHubList.vue"),
    aiHubDetail: () =>
      import(
        /*webpackChunkName: "AiHubDetail"*/ "@/views/aiHub/AiHubDetail.vue"
      )
  },
  subscribe: {
    main: () =>
      import(
        /*webpackChunkName: "SubscribeMain"*/ "@/views/subscribe/main/SubscribeMain.vue"
      )
  },
  organization: {
    main: () =>
      import(
        /*webpackChunkName: "OrganizationMain"*/ "@/views/organization/main/OrganizationMain.vue"
      ),
    inquiry: () =>
      import(
        /*webpackChunkName: "OrganizationInquiry"*/ "@/views/organization/inquiry/OrganizationInquiry.vue"
      )
  },
  organizationConsulting: {
    main: () =>
      import(
        /*webpackChunkName: "OrganizationConsultingMain"*/ "@/views/organizationConsulting/main/OrganizationConsultingMain.vue"
      ),
    inquiry: () =>
      import(
        /*webpackChunkName: "OrganizationConsultingInquiry"*/ "@/views/organizationConsulting/inquiry/OrganizationConsultingInquiry.vue"
      )
  },
  community: {
    main: () =>
      import(
        /*webpackChunkName: "Community"*/ "@/views/community/main/CommunityMain.vue"
      ),
    detail: () =>
      import(
        /*webpackChunkName: "Community"*/ "@/views/community/detail/CommunityDetail.vue"
      ),
    write: () =>
      import(
        /*webpackChunkName: "CommunityWrite"*/ "@/views/community/write/CommunityWrite.vue"
      )
  },
  blog: {
    main: () =>
      import(/*webpackChunkName: "BlogList"*/ "@/views/blog/BlogList.vue"),
    detail: () =>
      import(/*webpackChunkName: "BlogDetail"*/ "@/views/blog/BlogDetail.vue")
  },
  customerCenter: {
    parent: () =>
      import(
        /* webpackChunkName: "customerCenter" */ "@/views/customerCenter/parent/CustomerCenter.vue"
      ),
    faq: {
      main: () =>
        import(
          /* webpackChunkName: "CustomerCenterFAQ" */ "@/views/customerCenter/main/CustomerCenterMain.vue"
        )
    },
    write: {
      main: () =>
        import(
          /*webpackChunkName: "CustomerCenterWrite"*/ "@/views/customerCenter/write/CustomerCenterWrite.vue"
        )
    },
    myQnA: {
      main: () =>
        import(
          /*webpackChunkName: "CustomerCenterMyQnA"*/ "@/views/customerCenter/myQnA/CustomerCenterMyQnA.vue"
        )
    }
  },
  reference: {
    main: () =>
      import(
        /*webpackChunkName: "ReferenceList"*/ "@/views/reference/main/ReferenceMain.vue"
      ),
    detail: () =>
      import(
        /*webpackChunkName: "ReferenceDetail"*/ "@/views/reference/detail/ReferenceDetail.vue"
      )
  }
};
