import ApiService from "@/service/api/ApiService";
import { catchError, map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import MyTicketModel from "@/service/model/myTicket/MyTicketModel";

class MyTicketService extends ApiService {
  /**
   * 사용중 티켓 조회
   */
  public retrieveLiveTicketList(): Observable<MyTicket[]> {
    return this.get<MyTicket[]>("/ticket/detail", {}, true).pipe(
      map((ticketList: MyTicket[]) =>
        ticketList.map(ticket => MyTicketModel.create(ticket))
      ),
      catchError(() => of([]))
    );
  }

  /**
   * 기간만료 티켓 조회
   */
  public retrieveEndedTicketList(): Observable<MyTicket[]> {
    return this.get<MyTicket[]>("/ticket/detail/expired", {}, true).pipe(
      map((ticketList: MyTicket[]) =>
        ticketList.map(ticket => MyTicketModel.create(ticket))
      ),
      catchError(() => of([]))
    );
  }
}

export const myTicketService = new MyTicketService();
