import { createModel } from "@/util/ModelUtil";

export default class LectureCardModel {
  constructor(
    public courseId: number = 0,
    public lectureId: number = 0,
    public title: string = "",
    public cardImageUrl: string = "",
    public memoCount: number = 0
  ) {}

  public static create(lectureCard?: LectureCard): LectureCardModel {
    return createModel<LectureCardModel>(LectureCardModel, lectureCard);
  }
}
