import ApiService from "@/service/api/ApiService";
import { catchError, map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import PageModel from "@/service/model/page/PageModel";
import PostModel from "@/service/model/post/PostModel";
import PostCommentModel from "@/service/model/post/PostCommentModel";
import { CommunitySortType } from "@/enum/community/CommunitySortType";
import CategoryModel from "@/service/model/community/CategoryModel";
import ReplyModel from "@/service/model/post/ReplyModel";
import { CommunityCategoryType } from "@/enum/community/CommunityCategoryType";

class CommunityService extends ApiService {
  /**
   * 커뮤니티 카테고리 리스트 조회
   * @param organizationId
   */
  public retrieveCommunityCategoryList(
    organizationId: number
  ): Observable<CategoryModel[]> {
    return this.get<Category[]>(
      "community/organization/category/list",
      { organizationId },
      true
    ).pipe(
      map((categoryList: Category[]) =>
        categoryList.map(category => CategoryModel.create(category))
      ),
      catchError(() => of([]))
    );
  }

  /**
   * 글 상세 조회
   * @param id
   */
  public retrieveCommunityCategoryById(id: number): Observable<Category> {
    return this.get<Category>("/community/organization/category", {
      id
    }).pipe(
      map((category: Category) => CategoryModel.create(category)),
      catchError(() => of(new CategoryModel()))
    );
  }

  /**
   * 고정된 커뮤니티 리스트
   * @param id
   */
  public retrieveFixedCommunityList(id: number): Observable<Post[]> {
    return this.get<Post[]>("/community/list/fixed", { id }, true).pipe(
      map((postList: Post[]) => postList.map(post => PostModel.create(post))),
      catchError(() => of([]))
    );
  }

  /**
   * 커뮤니티 리스트
   * @param page
   * @param itemSize
   * @param sortBy
   * @param desc
   * @param categoryId
   * @param organizationId
   * @param filter
   * @param keyword
   * @param type
   */
  public retrieveCommunityList(
    page: number,
    itemSize: number,
    sortBy?: string,
    desc?: boolean,
    categoryId?: number,
    organizationId?: number,
    filter?: string,
    keyword?: string,
    type?: CommunityCategoryType
  ): Observable<PageModel<Post>> {
    return this.get<Page<Post>>(
      "/community/list",
      {
        page,
        itemSize,
        sortBy,
        desc,
        categoryId,
        organizationId,
        filter,
        keyword,
        type
      },
      true
    ).pipe(
      map((page: Page<Post>) => PageModel.create<Post>(page)),
      catchError(() => of(new PageModel<PostModel>()))
    );
  }

  /**
   * 마이페이지 커뮤니티 목록 조회
   * @param page
   * @param itemSize
   * @param categoryId
   * @param url
   */
  public getMyFreeBoardToData(
    page: number,
    itemSize: number,
    categoryId: number,
    url: string
  ): Observable<PageModel<Post>> {
    return this.get<Page<Post>>(
      url,
      {
        page,
        itemSize,
        categoryId
      },
      true
    ).pipe(
      map((page: Page<Post>) => PageModel.create<Post>(page)),
      catchError(() => of(new PageModel<PostModel>()))
    );
  }

  public retrieveCommunityById(id: number): Observable<PostModel> {
    return this.get<Post>("/community", { id }, true).pipe(
      map((post: Post) => PostModel.create(post)),
      catchError(() => of(new PostModel()))
    );
  }

  /**
   * 글 생성
   * @param postCreate
   */
  public createdCommunity(postCreate: PostCreate): Observable<Post> {
    return this.post<Post>("/community", postCreate, true).pipe(
      map((post: Post) => PostModel.create(post))
    );
  }

  /**
   * 글 생성
   * @param postUpdate
   */
  public updateCommunity(postUpdate: PostUpdate): Observable<Post> {
    return this.put<Post>("/community", postUpdate, true).pipe(
      map((post: Post) => PostModel.create(post))
    );
  }

  /**
   * 글 삭제
   * @param id
   */
  public deleteCommunity(id: number): Observable<boolean> {
    return this.put<boolean>("/community/disable", { id }, true).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * 관라자에 의한 글 삭제
   * @param id
   * @param reason
   */
  public deleteCommunityForManager(
    id: number,
    reason: string
  ): Observable<boolean> {
    return this.put<boolean>(
      "/community/disable/manager",
      { id, reason },
      true
    ).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * Course 단위 / 전체 질문 리스트 조회
   * @param organizationId
   * @param courseId
   * @param itemSize
   * @param page
   * @param sortBy
   * @param isLecturer
   */
  public retrieveCommunityListByCourseId(
    organizationId: number,
    courseId: number,
    itemSize: number,
    page: number,
    sortBy: CommunitySortType,
    isLecturer?: boolean
  ): Observable<Page<Post>> {
    return this.get<Page<Post>>(
      "/community/lecture/qna/list/course",
      {
        organizationId,
        courseId,
        itemSize,
        page,
        sortBy,
        isLecturer,
        desc: true
      },
      true
    ).pipe(
      map((postPage: Page<Post>) => PageModel.create<Post>(postPage)),
      catchError(() => of(new PageModel<Post>()))
    );
  }

  /**
   * Chapter 단위 질문 리스트 조회
   * @param organizationId
   * @param courseId
   * @param chapterId
   * @param itemSize
   * @param page
   * @param sortBy
   */
  public retrieveCommunityListByChapterId(
    organizationId: number,
    courseId: number,
    chapterId: number,
    itemSize: number,
    page: number,
    sortBy: CommunitySortType
  ): Observable<Page<Post>> {
    return this.get<Page<Post>>(
      "/community/lecture/qna/list/chapter",
      {
        organizationId,
        courseId,
        chapterId,
        itemSize,
        page,
        sortBy,
        desc: true
      },
      true
    ).pipe(
      map((postPage: Page<Post>) => PageModel.create<Post>(postPage)),
      catchError(() => of(new PageModel<Post>()))
    );
  }

  /**
   * 내 질문 리스트 조회
   * @param postSearch
   */
  public retrieveMyCommunityList(
    postSearch: PostSearch
  ): Observable<Page<Post>> {
    return this.get<Page<Post>>(
      "/mypage/community/question/list",
      postSearch,
      true
    ).pipe(
      map((postPage: Page<Post>) => PageModel.create<Post>(postPage)),
      catchError(() => of(new PageModel<Post>()))
    );
  }

  /**
   * 내 답변 질문 리스트 조회
   * @param postSearch
   */
  public retrieveCommunityListContainingMyAnswer(
    postSearch: PostSearch
  ): Observable<Page<Post>> {
    return this.get<Page<Post>>(
      "/mypage/community/answer/list",
      postSearch,
      true
    ).pipe(
      map((postPage: Page<Post>) => PageModel.create<Post>(postPage)),
      catchError(() => of(new PageModel<Post>()))
    );
  }

  /**
   * 내 북마크 리스트 조회
   * @param postSearch
   */
  public retrieveCommunityListContainingBookmark(
    postSearch: PostSearch
  ): Observable<Page<Post>> {
    return this.get<Page<Post>>(
      "/mypage/community/bookmark/list",
      postSearch,
      true
    ).pipe(
      map((postPage: Page<Post>) => PageModel.create<Post>(postPage)),
      catchError(() => of(new PageModel<Post>()))
    );
  }

  /**
   * 내 댓글 리스트 조회
   * @param postSearch
   */
  public retrieveCommunityListContainingMyComment(
    postSearch: PostSearch
  ): Observable<Page<Post>> {
    return this.get<Page<Post>>(
      "/mypage/community/comment/list",
      postSearch,
      true
    ).pipe(
      map((postPage: Page<Post>) => PageModel.create<Post>(postPage)),
      catchError(() => of(new PageModel<Post>()))
    );
  }

  /**
   * 내 댓글 리스트 조회
   * @param postSearch
   */
  public retrieveCommunityListContainingMyReply(
    postSearch: PostSearch
  ): Observable<Page<Post>> {
    return this.get<Page<Post>>(
      "/mypage/community/reply/list",
      postSearch,
      true
    ).pipe(
      map((postPage: Page<Post>) => PageModel.create<Post>(postPage)),
      catchError(() => of(new PageModel<Post>()))
    );
  }

  /**
   * 답변 리스트 상세 조회
   * @param postId
   */
  public retrieveCommunityAnswerList(postId: number): Observable<Post[]> {
    return this.get<Post[]>("/community/answer/list", { postId }, true).pipe(
      map((postList: Post[]) => postList.map(post => PostModel.create(post))),
      catchError(() => of([]))
    );
  }

  /**
   * 답변 생성
   * @param postId
   * @param content
   */
  public createCommunityAnswer(
    postId: number,
    content: string
  ): Observable<Post> {
    return this.post<Post>("/community/answer", { postId, content }, true).pipe(
      map((post: Post) => PostModel.create(post)),
      catchError(() => of(new PostModel()))
    );
  }

  /**
   * 답변 수정
   * @param id
   * @param content
   */
  public updateCommunityAnswer(id: number, content: string): Observable<Post> {
    return this.put<Post>("/community/answer", { id, content }, true).pipe(
      map((post: Post) => PostModel.create(post)),
      catchError(() => of(new PostModel()))
    );
  }

  /**
   * 답변 삭제 요청
   * @param id
   */
  public deleteCommunityAnswer(id: number): Observable<boolean> {
    return this.put<boolean>("/community/answer/disable", { id }, true).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * 관리자의 의한 답변 삭제 요청
   * @param id
   * @param reason
   */
  public deleteCommunityAnswerForManager(
    id: number,
    reason: string
  ): Observable<boolean> {
    return this.put<boolean>(
      "/community/answer/disable/manager",
      { id, reason },
      true
    ).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * 질문 좋아요 추가 요청
   * @param id
   */
  public createPostLikeList(id: number): Observable<boolean> {
    return this.post<boolean>("/community/like", { id }, true).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * 질문 좋아요 취소 요청
   * @param id
   */
  public deletePostLikeList(id: number): Observable<boolean> {
    return this.delete<boolean>("/community/like", { id }, true).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * 답변 좋아요 추가 요청
   * @param postAnswerId
   */
  public createPostAnswerLikeList(postAnswerId: number): Observable<boolean> {
    return this.post<boolean>(
      "/community/answer/like",
      { postAnswerId },
      true
    ).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * 답변 좋아요 취소 요청
   * @param postAnswerId
   */
  public deletePostAnswerLikeList(postAnswerId: number): Observable<boolean> {
    return this.delete<boolean>(
      "/community/answer/like",
      { postAnswerId },
      true
    ).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * 댓글 좋아요 추가 요청
   * @param commentId
   */
  public createCommentLikeList(commentId: number): Observable<boolean> {
    return this.post<boolean>(
      "/community/comment/like",
      { commentId },
      true
    ).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }
  /**
   * 답변 좋아요 취소 요청
   * @param commentId
   */
  public deleteCommentLikeList(commentId: number): Observable<boolean> {
    return this.delete<boolean>(
      "/community/comment/like",
      { commentId },
      true
    ).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  // TODO 사용안함 지우기
  /**
   * 질문 북마크 추가 요청
   * @param id
   */
  public createPostBookmark(id: number): Observable<boolean> {
    return this.post<boolean>("/community/bookmark", { id }, true).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * 질문 북마크 취소 요청
   * @param id
   */
  public deletePostBookmark(id: number): Observable<boolean> {
    return this.delete<boolean>("/community/bookmark", { id }, true).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * 댓글 리스트 상세 조회
   * @param postId
   */
  public retrieveCommunityCommentList(
    postId: number
  ): Observable<PostComment[]> {
    return this.get<PostComment[]>(
      "/community/comment/list",
      { postId },
      true
    ).pipe(
      map((commentList: PostComment[]) =>
        commentList.map(comment => PostCommentModel.create(comment))
      ),
      catchError(() => of([]))
    );
  }

  /**
   * 베스트 댓글 조회
   * @param postId
   */
  public retrieveBestCommunityComment(
    postId: number
  ): Observable<PostComment[]> {
    return this.get<PostComment[]>(
      "/community/best/comment",
      { postId },
      true
    ).pipe(
      map((bestCommentList: PostComment[]) =>
        bestCommentList.map(bestComment => PostCommentModel.create(bestComment))
      ),
      catchError(() => of([]))
    );
  }

  /**
   * 댓글 생성 요청
   * @param postId
   * @param postAnswerId
   * @param content
   */
  public createComment(
    content: string,
    postId: number,
    postAnswerId?: number
  ): Observable<PostComment> {
    return this.post<PostComment>(
      "/community/comment",
      { postId, postAnswerId, content },
      true
    ).pipe(
      map((postComment: PostComment) => PostCommentModel.create(postComment)),
      catchError(() => of(new PostCommentModel()))
    );
  }

  /**
   * 댓글 수정 요청
   * @param id
   * @param content
   */
  public updateComment(id: number, content: string): Observable<PostComment> {
    return this.put<PostComment>(
      "/community/comment",
      { id, content },
      true
    ).pipe(
      map((postComment: PostComment) => PostCommentModel.create(postComment)),
      catchError(() => of(new PostCommentModel()))
    );
  }

  /**
   * 댓글 삭제 요청
   * @param id
   */
  public deleteComment(id: number): Observable<boolean> {
    return this.put<boolean>("/community/comment/disable", { id }, true).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * 관리자에 의한 댓글 삭제 요청
   * @param id
   * @param reason
   */
  public deleteCommentForManager(
    id: number,
    reason: string
  ): Observable<boolean> {
    return this.put<boolean>(
      "/community/comment/disable/manager",
      { id, reason },
      true
    ).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * 조회수 증가 요청
   * @param postId
   */
  public addViewCount(postId: number): Observable<boolean> {
    return this.put<boolean>("/community/viewCount", { postId }).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * 글 상단 고정 추가/삭제
   * @param id
   * @param fixedFlag
   */
  public updateCommunityByIdAndIsFixed(
    id: number,
    fixedFlag: boolean
  ): Observable<boolean> {
    return this.put<boolean>("/community/fixed", { id, fixedFlag }, true).pipe(
      map((isSuccess: boolean) => isSuccess)
    );
  }

  /**
   * 답글 생성
   * @param commentId
   * @param content
   */
  public createReply(commentId: number, content: string): Observable<Reply> {
    return this.post<Reply>(
      "/community/reply",
      { commentId, content },
      true
    ).pipe(
      map((reply: Reply) => ReplyModel.create(reply)),
      catchError(() => of(new ReplyModel()))
    );
  }

  /**
   * 답글 리스트 조회
   * @param commentId
   */
  public retrieveReplyList(commentId: number): Observable<Reply[]> {
    return this.get<Reply[]>("/community/reply/list", { commentId }, true).pipe(
      map((replyList: Reply[]) =>
        replyList.map(reply => ReplyModel.create(reply))
      ),
      catchError(() => of([]))
    );
  }

  /**
   * 답글 좋아요 추가 요청
   * @param replyId
   */
  public createReplyLikeList(replyId: number): Observable<boolean> {
    return this.post<boolean>("/community/reply/like", { replyId }, true).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * 답글 좋아요 취소 요청
   * @param replyId
   */
  public deleteReplyLikeList(replyId: number): Observable<boolean> {
    return this.delete<boolean>(
      "/community/reply/like",
      { replyId },
      true
    ).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * 답글 수정
   * @param id
   * @param content
   */
  public updateReply(id: number, content: string): Observable<Reply> {
    return this.put<Reply>("/community/reply", { id, content }, true).pipe(
      map((reply: Reply) => ReplyModel.create(reply)),
      catchError(() => of(new ReplyModel()))
    );
  }

  /**
   * 답글 삭제
   * @param id
   */
  public deleteReply(id: number): Observable<boolean> {
    return this.put<boolean>("/community/reply/disable", { id }, true).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * 답글 삭제
   * @param id
   * @param reason
   */
  public deleteReplyForManager(
    id: number,
    reason: string
  ): Observable<boolean> {
    return this.put<boolean>(
      "/community/reply/disable/manager",
      { id, reason },
      true
    ).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }
}

export const communityService = new CommunityService();
