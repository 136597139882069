import ApiService from "@/service/api/ApiService";
import { catchError, map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import LectureModel from "@/service/model/lecture/LectureModel";
import LectureCardModel from "@/service/model/lecture/LectureCardModel";

class LectureService extends ApiService {
  /**
   * 강의 상세
   * @param lectureId
   */
  public retrieveLecture(lectureId: number): Observable<LectureModel> {
    return this.get<Lecture>("/lecture", { id: lectureId }).pipe(
      map((lecture: Lecture) => LectureModel.create(lecture)),
      catchError(() => of(new LectureModel()))
    );
  }

  /**
   * 강의 상세
   * @param courseId
   * @param organizationId
   */
  public retrieveLatestOrganizationLecture(
    courseId: number,
    organizationId: number
  ): Observable<LectureModel> {
    return this.get<Lecture>(
      "/lecture/course/organization/first",
      { courseId, organizationId },
      true
    ).pipe(
      map((lecture: Lecture) => LectureModel.create(lecture)),
      catchError(() => of(new LectureModel()))
    );
  }

  /**
   * 강의 상세 - Manager 용
   * @param lectureId
   */
  public retrieveLectureForManager(
    lectureId: number
  ): Observable<LectureModel> {
    return this.get<Lecture>("/lecture/manager", { id: lectureId }, true).pipe(
      map((lecture: Lecture) => LectureModel.create(lecture)),
      catchError(() => of(new LectureModel()))
    );
  }

  /**
   * 강의 리스트 조회 - Lecturer And Admin 전용
   */
  public retrieveLectureListForManager(): Observable<Array<LectureModel>> {
    return this.get<Lecture[]>("/lecture/list/manager", {}, true).pipe(
      map((lectureList: Lecture[]) =>
        lectureList.map((lecture: Lecture) => LectureModel.create(lecture))
      ),
      catchError(() => of([]))
    );
  }

  /**
   * 강의 리스트 조회 - Lecturer And Admin 전용
   */
  public retrieveLectureListForUser(): Observable<Lecture[]> {
    return this.get<Lecture[]>("/lecture/list/user", {}, true).pipe(
      map((lectureList: Lecture[]) =>
        lectureList.map((lecture: Lecture) => LectureModel.create(lecture))
      ),
      catchError(() => of([]))
    );
  }

  /**
   * 공동 강의 리스트 조회
   * @param courseId
   */
  public retrieveLectureList(
    courseId: number
  ): Observable<Array<LectureModel>> {
    return this.get<Lecture[]>("/lecture/list/course", { courseId }).pipe(
      map((lectureList: Lecture[]) =>
        lectureList.map((lecture: Lecture) => LectureModel.create(lecture))
      ),
      catchError(() => of([]))
    );
  }

  /**
   * K-Digital-Credit 강의 유무 확인
   * @param lectureId
   */
  public retrieveIsKDigitalLecture(lectureId: number): Observable<boolean> {
    return this.get<boolean>(
      "/lecture/check/kDigitalLecture",
      { lectureId },
      true
    ).pipe(map((isSuccess: boolean) => isSuccess));
  }

  /**
   * 메모 수가 포함된 코스 카드 리스트 조회
   */
  public retrieveLectureCardListWithMemoCount(): Observable<LectureCard[]> {
    return this.get<LectureCard[]>(
      "/lecture/card/list/memoCount",
      {},
      true
    ).pipe(
      map((lectureCardList: LectureCard[]) =>
        lectureCardList.map(lectureCard => LectureCardModel.create(lectureCard))
      ),
      catchError(() => of([]))
    );
  }

  /**
   * 사용자 구독 등급이 강의를 구독할 수 있는지 확인
   */
  public checkLectureAvailableForSubscription(
    courseId: number
  ): Observable<boolean> {
    return this.get<boolean>(
      "/lecture/course/subscription/available",
      { courseId },
      true
    ).pipe(
      map((isAvailable: boolean) => isAvailable),
      catchError(() => of(false))
    );
  }
}

export const lectureService = new LectureService();
