import Course from "@/store/module/Course";
import User from "@/store/module/User";
import Dialog from "@/store/module/modal/Dialog";
import Alert from "@/store/module/modal/Alert";
import Confirm from "@/store/module/modal/Confirm";
import LectureCategory from "@/store/module/LectureCategory";
import Loading from "@/store/module/modal/Loading";
import Lecture from "@/store/module/Lecture";
import Theme from "@/store/module/Theme";
import Organization from "@/store/module/Organization";
import History from "@/store/module/History";

export const storeOptions = {
  modules: {
    user: User,
    course: Course,
    dialog: Dialog,
    alert: Alert,
    confirm: Confirm,
    loading: Loading,
    lecture: Lecture,
    lectureCategory: LectureCategory,
    theme: Theme,
    organization: Organization,
    history: History
  }
};
