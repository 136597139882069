import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import LectureModel from "@/service/model/lecture/LectureModel";
import MyLectureModel from "@/service/model/myLecture/MyLectureModel";
import SubChapterModel from "@/service/model/subChapter/SubChapterModel";
import MySubChapterModel from "@/service/model/mySubChapter/MySubChapterModel";
import CourseModel from "@/service/model/course/CourseModel";
import { myLectureService } from "@/service/api/MyLectureService";

@Module({ namespaced: true, name: "lecture" })
export default class Lecture extends VuexModule {
  public lecture: LectureModel = new LectureModel();
  public myLecture: MyLecture = new MyLectureModel();
  public subChapter: SubChapter = new SubChapterModel();
  public mySubChapter: MySubChapter = new MySubChapterModel();
  public course: Course = new CourseModel();
  public currentVideoTime = 0;
  public changeVideoTime: number | null = null;

  @Mutation
  public isPreviewSubChapter(subChapterId: number): boolean {
    return this.subChapterList.some(
      subChapter => subChapter.id === subChapterId && subChapter.isPreview
    );
  }

  public get subChapterList(): SubChapter[] {
    return this.lecture.chapterList.flatMap(chapter => chapter.subChapterList);
  }

  public get mySubChapterList(): MySubChapter[] {
    return this.myLecture.myChapterList.flatMap(
      myChapter => myChapter.mySubChapterList
    );
  }

  @Mutation
  public setLecture(lecture: LectureModel): void {
    lecture.chapterList.forEach(chapter =>
      chapter.subChapterList.sort(
        (subChapter1: SubChapter, subChapter2) =>
          subChapter1.order - subChapter2.order
      )
    );
    this.lecture = lecture;
  }

  @Mutation
  public setMyLecture(myLecture: MyLecture): void {
    this.myLecture = myLecture;
  }

  @Mutation
  public setSubChapter(subChapter: SubChapter): void {
    this.subChapter = subChapter;
  }

  @Mutation
  public setMySubChapter(mySubChapter: MySubChapter): void {
    this.mySubChapter = mySubChapter;
  }

  @Mutation
  public setCourse(course: Course): void {
    this.course = course;
  }

  @Mutation
  public reset(): void {
    this.lecture = new LectureModel();
    this.myLecture = new MyLectureModel();
    this.subChapter = new SubChapterModel();
    this.mySubChapter = new MySubChapterModel();
    this.course = new CourseModel();
    this.currentVideoTime = 0;
    this.changeVideoTime = null;
  }

  @Mutation
  public setCurrentVideoTime(currentVideoTime: number): void {
    this.currentVideoTime = currentVideoTime;
  }

  @Mutation
  public setChangeVideoTime(changeVideoTime: number | null): void {
    this.changeVideoTime = changeVideoTime;
  }

  @Action
  public getMyLectureByLectureId(lectureId: number): void {
    myLectureService
      .retrieveMyLecture(lectureId)
      .subscribe((myLecture: MyLecture) => {
        this.context.commit("setMyLecture", myLecture);
      });
  }

  public get chapterTitle(): string {
    const chapter: Chapter = this.lecture.chapterList.find(
      chapter => chapter.id === this.subChapter.chapterId
    ) as Chapter;
    return chapter ? chapter.title : "";
  }
}
