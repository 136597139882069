import { CoursePromotionType } from "@/enum/CoursePromotionType";
import { createModel } from "@/util/ModelUtil";

export default class CoursePromotionsModel {
  constructor(
    public id: number = 0,
    public type: CoursePromotionType = CoursePromotionType.MAIN,
    public title: string = "",
    public exposure: boolean = false,
    public order: number = 0,
    public createdAt: number = new Date().getTime(),
    public updatedAt: number = new Date().getTime(),
    public courseCardList: CourseCard[] = []
  ) {}

  public static create(
    coursePromotion?: CoursePromotion
  ): CoursePromotionsModel {
    return createModel<CoursePromotionsModel>(
      CoursePromotionsModel,
      coursePromotion
    );
  }
}
