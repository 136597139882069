export enum LangType {
  NONE = "none",
  PYTHON = "PYTHON",
  PYTHON_DL = "PYTHON_DL",
  PYTHON_DL_GPU = "PYTHON_DL_GPU",
  CPP_GPP = "CPP_GPP",
  C = "C",
  SQL_MYSQL = "SQL_MYSQL",
  SQL_POSTGRESQL = "SQL_POSTGRESQL",
  Java = "JAVA",
  Windows = "WINDOWS"
}
