import { RouterMenuName } from "@/enum/RouterMenuName";
import { menu } from "@/router/menu";
import { HeaderClassType } from "@/enum/HeaderClassType";
import { LayoutType } from "@/enum/LayoutType";
import { RouteConfig } from "vue-router";
import RouterMeta from "@/router/model/RouterMeta";

const meta: RouterMeta = {
  headerClass: `${HeaderClassType.My}`,
  layout: LayoutType.FormLayout,
  guard: {
    login: true
  }
};

const my: RouteConfig = {
  path: "/my",
  component: menu.my.parent,
  children: [
    {
      path: "course",
      component: menu.my.course.main,
      name: RouterMenuName.MypageCourse,
      meta
    },
    {
      path: "course/:lectureId",
      component: menu.my.course.result,
      name: RouterMenuName.MyPageCourseResult,
      meta
    },

    {
      path: "memo",
      component: menu.my.memo.main,
      name: RouterMenuName.MypageMemo,
      meta
    },
    {
      path: "wishList",
      component: menu.my.wishList.main,
      name: RouterMenuName.MypageWishList,
      meta
    },
    {
      path: "payment",
      component: menu.my.payment.main,
      name: RouterMenuName.MypagePayment,
      meta
    },
    {
      path: "coupon",
      component: menu.my.coupon.main,
      name: RouterMenuName.MyPageCoupon,
      meta
    },
    {
      path: "subscribe",
      component: menu.my.subscribe.main,
      name: RouterMenuName.MypageSubscribe,
      meta
    },
    {
      path: "notice",
      component: menu.my.notice.main,
      name: RouterMenuName.MypageNotice,
      meta
    },
    {
      path: "alarm",
      component: menu.my.alarm.main,
      name: RouterMenuName.MypageAlarm,
      meta
    },
    {
      path: "profile",
      component: menu.my.profile.main,
      name: RouterMenuName.MypageProfile,
      meta
    },
    {
      path: "report",
      component: menu.my.report.main,
      children: [
        {
          path: "lecture",
          component: menu.my.report.lecture,
          name: RouterMenuName.MypageLectureReport,
          meta
        },
        {
          path: "lecture/:lectureId",
          component: menu.my.report.lectureDetail,
          name: RouterMenuName.MypageLectureDetailReport,
          meta
        },
        {
          path: "lecture/survey/:lectureId",
          component: menu.my.report.lectureSurvey,
          name: RouterMenuName.MypageLectureSurveyReport,
          meta
        },
        {
          path: "student",
          component: menu.my.report.student,
          name: RouterMenuName.MypageStudentReport,
          meta
        },
        {
          path: "student/:userId",
          component: menu.my.report.studentDetail,
          name: RouterMenuName.MypageStudentDetailReport,
          meta
        },
        {
          path: ":lectureId/:userId",
          component: menu.my.report.studentLectureDetail,
          name: RouterMenuName.MypageStudentLectureDetailReport,
          meta
        }
      ]
    },
    {
      path: "specialTrackReport",
      component: menu.my.specialTrackReport.main,
      name: RouterMenuName.MypageSpecialTrackReport,
      meta
    },
    {
      path: "specialTrackReport/:specialTrackId",
      component: menu.my.specialTrackReport.detail,
      name: RouterMenuName.MypageSpecialTrackReportDetail,
      meta
    },
    {
      path: "qna/lecturer",
      component: menu.my.lecturerQna.main,
      name: RouterMenuName.MypageLecturerQNA,
      meta
    },
    {
      path: "community",
      component: menu.my.community.main,
      name: RouterMenuName.MypageCommunity,
      meta
    },
    {
      path: "ticket",
      component: menu.my.ticket.main,
      name: RouterMenuName.MypageTicket,
      meta
    },
    {
      path: "specialTrack",
      component: menu.my.specialTrack.main,
      name: RouterMenuName.MypageSpecialTrack,
      meta
    }
  ]
};

export const myPage: RouteConfig = my;
