export enum RouterMenuName {
  Home = "Home",
  CourseMain = "CourseMain",
  CourseDetail = "CourseDetail",
  SignIn = "SignIn",
  SignInFail = "SignInFail",
  SignUp = "SignUp",
  SignUpNormal = "SignUpNormal",
  SignUpComplete = "SignUpComplete",
  MypageCourse = "MypageCourse",
  MypageCommunity = "MypageCommunity",
  MypageMemo = "MypageMemo",
  MypageWishList = "MypageWishList",
  MypageNotice = "MypageNotice",
  MyPageCoupon = "MyPageCoupon",
  MypagePayment = "MypagePayment",
  MypageSubscribe = "MypageSubscribe",
  MypageAlarm = "MypageAlarm",
  MypageProfile = "MypageProfile",
  MypageLecturerQNA = "MypageLecturerQNA",
  MypageLectureReport = "MypageLectureReport",
  MypageLectureDetailReport = "MypageLectureDetailReport",
  MypageLectureSurveyReport = "MypageLectureSurveyReport",
  MypageStudentReport = "MypageStudentReport",
  MypageStudentDetailReport = "MypageStudentDetailReport",
  MypageStudentLectureDetailReport = "MypageStudentLectureDetailReport",
  MypageSpecialTrackReport = "MypageSpecialTrackReport",
  MypageSpecialTrackReportDetail = "MypageSpecialTrackReportDetail",
  MypageFreeboard = "MypageFreeboard",
  MypageTicket = "MypageTicket",
  MypageSpecialTrack = "MypageSpecialTrack",

  LectureMain = "LectureMain",
  LectureCodeEditor = "LectureCodeEditor",
  LectureEditor = "LectureEditor",
  AiHubStaticListAi = "AiHubStaticListAi",
  AiHubStaticListDa = "AiHubStaticListDa",
  AiHubStaticDetailAi = "AiHubStaticDetailAi",
  AiHubStaticDetailDa = "AiHubStaticDetailDa",
  AuthUserEmail = "AuthUserEmail",
  AuthUserSocial = "AuthUserSocial",
  AuthUserTerms = "AuthUserTerms",
  MyPageCourseResult = "MyPageCourseResult",
  AuthUserPasswordChange = "AuthUserPasswordChange",
  SpecialTrack = "SpecialTrack",
  SpecialTrackDetail = "SpecialTrackDetail",
  SpecialTrackApply = "SpecialTrackApply",
  SpecialTrackApplyComplete = "SpecialTrackApplyComplete",
  Cart = "Cart",
  Payment = "Payment",
  AuthPayment = "AuthPayment",
  AuthOrganization = "AuthOrganization",
  PaymentComplete = "PaymentComplete",
  Subscribe = "Subscribe",
  Organization = "Organization",
  OrganizationInquiry = "OrganizationInquiry",
  OrganizationConsulting = "OrganizationConsulting",
  OrganizationConsultingInquiry = "OrganizationConsultingInquiry",
  Community = "Community",
  CommunityWrite = "CommunityWrite",
  CommunityDetail = "CommunityDetail",
  BlogList = "BlogList",
  BlogDetail = "BlogDetail",
  CustomerCenterFAQ = "CustomerCenterFAQ",
  CustomerCenterWrite = "CustomerCenterWrite",
  CustomerCenterMyQNA = "CustomerCenterMyQNA",
  Reference = "Reference",
  ReferenceDetail = "ReferenceDetail",
  MyPasswordChange = "MyPasswordChange"
}
