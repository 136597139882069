import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import UserModel from "@/service/model/user/UserModel";
import { UserType } from "@/enum/UserType";
import { alarmService } from "@/service/api/AlarmService";
import { cartService } from "@/service/api/CartService";
import { courseService } from "@/service/api/CourseService";
import SubscribeCardModel from "@/service/model/subscribe/SubscribeCardModel";
import { subscribeService } from "@/service/api/SubscribeService";
import ProductModel from "@/service/model/product/ProductModel";
import {
  SubscribeType,
  SubscribeTypeClassName,
  SubscribeTypeText
} from "@/enum/SubscribeType";
import { isCurrentDateAfter } from "@/util/DateUtil";
import { myTicketService } from "@/service/api/MyTicketService";
import { SubscriberStatusType } from "@/enum/SubscriberStatusType";
import * as Sentry from "@sentry/vue";

@Module({ namespaced: true, name: "user" })
export default class User extends VuexModule {
  public userId = -1;
  public userProfileImageUrl = "";
  public userNickName = "";
  public organizationId = 0;
  public user = new UserModel();
  public unReadAlarmCount = 0;
  public alarmFeedList: AlarmFeed[] = [];
  public cartList: Cart[] = [];
  public wishCourseList: WishCourse[] = [];
  public subscribeCard: SubscribeCard = new SubscribeCardModel();
  public cellPhoneAuth = false;
  public tempCellPhone = "";
  public ticket: MyTicket[] = [];

  @Mutation
  public setUser(user: UserModel): void {
    this.userId = user.id;
    this.userProfileImageUrl = user.profileImageUrl;
    this.userNickName = user.nickName;
    this.organizationId = user.organizationId;
    this.user = user;
    alarmService
      .retrieveUnreadAlarmList(1)
      .subscribe((alarmFeedPage: Page<AlarmFeed>) => {
        this.alarmFeedList = alarmFeedPage.pagingData;
      });
    alarmService
      .retrieveUnReadAlarmCount()
      .subscribe(count => (this.unReadAlarmCount = count));
    cartService.retrieveCartList().subscribe((cartList: Cart[]) => {
      this.cartList = cartList;
    });
    courseService
      .retrieveWishCourseList()
      .subscribe((wishCourseList: WishCourse[]) => {
        this.wishCourseList = wishCourseList;
      });
    subscribeService
      .retrieveSubscribeCard()
      .subscribe((subscribeCard: SubscribeCard) => {
        subscribeCard.subscribe.product = ProductModel.create(
          subscribeCard.subscribe.product
        );
        this.subscribeCard = subscribeCard;
      });
    myTicketService
      .retrieveLiveTicketList()
      .subscribe((ticketList: MyTicket[]) => {
        this.ticket = ticketList;
      });
    Sentry.configureScope(function(scope) {
      scope.setTag("team", "frontend");
      scope.setUser({
        id: user.id,
        email: user.email
      });
    });
  }

  @Mutation
  public updateUnReadAlarmCount(unReadAlarmCount: number): void {
    this.unReadAlarmCount = unReadAlarmCount;
  }

  @Mutation
  public updateAlarmFeedList(alarmFeedList: AlarmFeed[]): void {
    this.alarmFeedList = alarmFeedList;
  }

  @Mutation
  public setCartList(cartList: Cart[]): void {
    this.cartList = cartList;
  }

  @Mutation
  public addCartList(cart: Cart): void {
    this.cartList.unshift(cart);
  }

  @Mutation
  public removeCartById(id: number): void {
    this.cartList = this.cartList.filter(cart => cart.id !== id);
  }

  @Mutation
  public addWishCourse(wishCourse: WishCourse): void {
    this.wishCourseList.unshift(wishCourse);
  }

  @Mutation
  public removeWishCourseByCourseId(courseId: number): void {
    this.wishCourseList = this.wishCourseList.filter(
      wishCourse => wishCourse.courseId !== courseId
    );
  }

  @Mutation
  public setSubscribeCard(subscribeCard: SubscribeCard): void {
    this.subscribeCard = subscribeCard;
  }

  @Mutation
  public setCellPhoneAuth(auth: boolean): void {
    this.cellPhoneAuth = auth;
  }

  @Mutation
  public setCellPhoneNumber(cellphone: string): void {
    this.tempCellPhone = cellphone;
  }

  @Action
  public getSubscribeCard(): void {
    subscribeService
      .retrieveSubscribeCard()
      .subscribe((subscribeCard: SubscribeCard) => {
        subscribeCard.subscribe.product = ProductModel.create(
          subscribeCard.subscribe.product
        );
        this.context.commit("setSubscribeCard", subscribeCard);
      });
  }

  public get isNormalUser(): boolean {
    let normal = false;
    switch (this.user.type) {
      case UserType.K_DIGITAL_CREDIT:
      case UserType.NORMAL:
      case UserType.ORG:
        normal = true;
        break;
    }
    return normal;
  }

  public get isAdminUser(): boolean {
    let normal = false;
    switch (this.user.type) {
      case UserType.ADMIN:
      case UserType.S_ADMIN:
        normal = true;
        break;
    }
    return normal;
  }

  public get isOrgManagerUser(): boolean {
    let normal = false;
    switch (this.user.type) {
      case UserType.ORG_MANAGER:
        normal = true;
        break;
    }
    return normal;
  }

  public get isLecturerUser(): boolean {
    let normal = false;
    switch (this.user.type) {
      case UserType.LECTURER:
        normal = true;
        break;
    }
    return normal;
  }

  public get userTypeName(): string {
    if (this.user.type === UserType.S_ADMIN) return "최고 관리자";
    else if (this.user.type === UserType.ADMIN) return "일반 관리자";
    else if (this.user.type === UserType.ORG_MANAGER) return "기업 관리자";
    else if (this.user.type === UserType.LECTURER) return "강사";
    else if (this.user.type === UserType.OPERATOR) return "운영자";
    else if (this.user.type === UserType.ORG) return "기업 회원";
    else if (this.user.type === UserType.K_DIGITAL_CREDIT)
      return "K-디지털-크레딧 회원";
    else return "일반 회원";
  }

  public get subscribePhrase(): string {
    return SubscribeTypeText[this.userSubscribe.type as SubscribeType];
  }

  public get subscribeStyle(): { [key: string]: boolean } {
    return {
      [SubscribeTypeClassName[this.userSubscribe.type as SubscribeType]]: true
    };
  }

  public get userSubscribe(): Subscribe {
    return this.subscribeCard.subscribe;
  }

  public get isAvailableSubscriber(): boolean {
    return (
      this.userSubscribe.id !== 0 &&
      !isCurrentDateAfter(this.subscribeCard.subscriber.expiredDate, "date")
    );
  }

  public get subscribeType(): null | SubscribeType {
    const isNotLogin = this.user.id === -1;
    const isNotSubscriber = ![
      SubscriberStatusType.PROCEEDING,
      SubscriberStatusType.CANCELING
    ].includes(this.subscribeCard.subscriber.status as SubscriberStatusType);

    return isNotLogin || isNotSubscriber
      ? null
      : (this.subscribeCard.subscribe.type as SubscribeType);
  }
}
