import { RouterMenuName } from "@/enum/RouterMenuName";
import { menu } from "@/router/menu";
import { HeaderClassType } from "@/enum/HeaderClassType";
import { LayoutType } from "@/enum/LayoutType";
import { RouteConfig } from "vue-router";
import RouterMeta from "@/router/model/RouterMeta";

const signUpMeta: RouterMeta = {
  headerClass: HeaderClassType.My,
  layout: LayoutType.NavigationLayout,
  guard: {
    login: false
  }
};

const signUp: RouteConfig[] = [
  {
    path: "/signUp",
    name: RouterMenuName.SignUp,
    component: menu.signUp.main,
    meta: signUpMeta
  },
  {
    path: "/signUp/normal",
    name: RouterMenuName.SignUpNormal,
    component: menu.signUp.normal,
    meta: signUpMeta
  },
  {
    path: "/signUp/complete",
    name: RouterMenuName.SignUpComplete,
    component: menu.signUp.complete,
    meta: signUpMeta
  }
];

export default signUp;
