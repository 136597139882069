import { createModel } from "@/util/ModelUtil";

export default class PageableModel {
  constructor(
    public last: boolean = true,
    public pageNumber: number = 1,
    public totalElementCount: number = 0
  ) {}

  public static create<T>(pageableDTO?: PageableModel): PageableModel {
    return createModel<PageableModel>(PageableModel, pageableDTO);
  }
}
