import { createModel } from "@/util/ModelUtil";
import ProductModel from "@/service/model/product/ProductModel";

export default class WishCourseModel {
  constructor(
    public id: number = 0,
    public courseId: number = 0,
    public title: string = "",
    public subTitle: string = "",
    public cardImageUrl: string = "",
    public subscribeType: string = "",
    public product: Product = new ProductModel(),
    public myLectureCardList: MyLectureCard[] = []
  ) {}

  public static create(wishCourse?: WishCourse): WishCourseModel {
    return createModel<WishCourseModel>(WishCourseModel, wishCourse);
  }
}
