import { RouterMenuName } from "@/enum/RouterMenuName";
import { menu } from "@/router/menu";
import { HeaderClassType } from "@/enum/HeaderClassType";
import { LayoutType } from "@/enum/LayoutType";
import { RouteConfig } from "vue-router";
import RouterMeta from "@/router/model/RouterMeta";

const meta: RouterMeta = {
  headerClass: `${HeaderClassType.My}`,
  layout: LayoutType.NavigationLayout
};

const organizationConsulting: RouteConfig[] = [
  {
    path: "/organization/consulting",
    component: menu.organizationConsulting.main,
    name: RouterMenuName.OrganizationConsulting,
    meta: meta
  },
  {
    path: "/organization/consulting/inquiry",
    component: menu.organizationConsulting.inquiry,
    name: RouterMenuName.OrganizationConsultingInquiry,
    meta: meta
  }
];

export const organizationConsultingPage: RouteConfig[] = organizationConsulting;
