import ApiService from "@/service/api/ApiService";
import { catchError, map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import SubscribeGroupModel from "@/service/model/subscribe/SubscribeGroupModel";
import { SubscribeType } from "@/enum/SubscribeType";
import SubscribeCardModel from "@/service/model/subscribe/SubscribeCardModel";

class SubscribeService extends ApiService {
  /**
   * 구독 그룹 리스트 조회
   */
  public retrieveSubscribeGroupList(): Observable<SubscribeGroup[]> {
    return this.get<SubscribeGroup[]>("/subscribe/group/list").pipe(
      map((subscribeGroupList: SubscribeGroup[]) =>
        subscribeGroupList.map(subscribeGroup =>
          SubscribeGroupModel.create(subscribeGroup)
        )
      ),
      catchError(() => of([]))
    );
  }

  /**
   * 구독 그룹 조회
   * @param subscribeType
   * @param isWithCourseCount
   */
  public retrieveSubscribeGroupByType(
    subscribeType: SubscribeType,
    isWithCourseCount = false
  ): Observable<SubscribeGroup> {
    return this.get<SubscribeGroup>("/subscribe/group", {
      subscribeType,
      isWithCourseCount
    }).pipe(
      map((subscribeGroup: SubscribeGroup) =>
        SubscribeGroupModel.create(subscribeGroup)
      ),
      catchError(() => of(new SubscribeGroupModel()))
    );
  }

  /**
   * 간단한 구독 그룹 조회
   */
  public retrieveSimpleSubscribeGroupList(): Observable<SubscribeGroup[]> {
    return this.get<SubscribeGroup[]>("/subscribe/group/list/simple").pipe(
      map((subscribeGroupList: SubscribeGroup[]) =>
        subscribeGroupList.map(subscribeGroup =>
          SubscribeGroupModel.create(subscribeGroup)
        )
      ),
      catchError(() => of([]))
    );
  }

  /**
   * 구독 자동결제 키 생성
   * @param subscribeCardInfo
   */
  public createBillingKey(
    subscribeCardInfo: SubscribeCardInfo
  ): Observable<string> {
    return this.post<string>(
      "/subscribe/billingKey",
      subscribeCardInfo,
      true
    ).pipe(map((billingKey: string) => billingKey));
  }

  /**
   * 구독 결제 요청
   * @param productId
   * @param couponId
   */
  public createPayment(
    productId: number,
    couponId: number | null,
    userPhone?: string
  ): Observable<number> {
    return this.post<number>(
      "/subscribe",
      { productId, couponId, userPhone },
      true
    ).pipe(map((paymentHistoryId: number) => paymentHistoryId));
  }

  /**
   * 구독 카드 조회
   */
  public retrieveSubscribeCard(): Observable<SubscribeCard> {
    return this.get<SubscribeCard>("/subscribe", {}, true).pipe(
      map((subscribeCard: SubscribeCard) =>
        SubscribeCardModel.create(subscribeCard)
      ),
      catchError(() => of(new SubscribeCardModel()))
    );
  }

  /**
   * 구독 진행중 상태 변경
   */
  public updateSubscriberStatusToProceeding(): Observable<boolean> {
    return this.put<boolean>("/subscribe/status/proceeding", {}, true).pipe(
      map((isSuccess: boolean) => isSuccess)
    );
  }

  /**
   * 구독 취소중 상태로 변경
   * @param surveyQuestionId
   * @param answer
   * @param textAnswer
   */
  public updateSubscriberStatusToCanceling(
    surveyQuestionId: number,
    answer: number[],
    textAnswer: string
  ): Observable<boolean> {
    return this.put<boolean>(
      "/subscribe/status/canceling",
      { surveyQuestionId, answer, textAnswer },
      true
    ).pipe(map((isSuccess: boolean) => isSuccess));
  }

  /**
   * 구독 취소중 상태로 변경
   * @param changeSubscribeId
   */
  public updateSubscriberByChangeSubscribeId(
    changeSubscribeId: number
  ): Observable<boolean> {
    return this.put<boolean>(
      "/subscribe/subscriber/changeSubscribe",
      { changeSubscribeId },
      true
    ).pipe(map((isSuccess: boolean) => isSuccess));
  }

  /**
   * 구독에 쿠폰 등록
   * @param userMapCouponId
   */
  public updateSubscriberByCouponMapUserId(
    userMapCouponId: number
  ): Observable<boolean> {
    return this.put<boolean>(
      "/subscribe/subscriber/coupon",
      { userMapCouponId },
      true
    ).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * 구독자 데이터의 쿠폰 제거
   */
  public removeCouponFromSubscriber(): Observable<boolean> {
    return this.put<boolean>(
      "/subscribe/subscriber/coupon/cancel",
      {},
      true
    ).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * 구독자 데이터의 변경될 구독 Id 제거
   */
  public removeChangeSubscribeIdFromSubscriber(): Observable<boolean> {
    return this.put<boolean>(
      "/subscribe/subscriber/changeSubscribe/cancel",
      {},
      true
    ).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }
}

export const subscribeService = new SubscribeService();
