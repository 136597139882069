import { createModel } from "@/util/ModelUtil";
import { SubscribeType } from "@/enum/SubscribeType";
import ProductModel from "@/service/model/product/ProductModel";

export default class SubscribeModel {
  constructor(
    public id: number = 0,
    public title: string = "",
    public period: number = 0,
    public type: SubscribeType = SubscribeType.BASIC,
    public product: Product = new ProductModel(),
    public createdAt: number = new Date().getTime(),
    public updatedAt: number = new Date().getTime()
  ) {}

  public static create(subscribe?: Subscribe): SubscribeModel {
    return createModel<SubscribeModel>(SubscribeModel, subscribe);
  }
}
