import { createModel } from "@/util/ModelUtil";

export default class MyTicketModel {
  constructor(
    public id: number = 0,
    public title: string = "",
    public status: string = "",
    public startedAt: number = new Date().getTime(),
    public expiredAt: number = new Date().getTime(),
    public lectureList: MyTicketLecture[] = []
  ) {}

  public static create(myTicket?: MyTicket): MyTicketModel {
    return createModel<MyTicketModel>(MyTicketModel, myTicket);
  }
}
