import { createModel } from "@/util/ModelUtil";

export default class CouponModel {
  constructor(
    public id: number = 0,
    public title: string = "",
    public saleRate: number = 0,
    public salePrice: number = 0,
    public maxTotalAmount: number = 0,
    public image: string = "",
    public periodDay: number = 0,
    public startedAt: number = 0,
    public endedAt: number = 0,
    public createdAt: number = new Date().getTime(),
    public updatedAt: number = new Date().getTime(),
    public issuerId: number = 0,
    public serialCode: string = "",
    public duplicatableFlag: boolean = false
  ) {}

  public static create(coupon?: Coupon): CouponModel {
    return createModel<CouponModel>(CouponModel, coupon);
  }
}
