import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import LoadingModel from "@/components/common/modal/model/LoadingModel";

@Module({ namespaced: true, name: "loading" })
export default class Loading extends VuexModule {
  public isOpen = false;
  public model: LoadingModel = {};

  @Mutation
  public open(model?: LoadingModel): void {
    this.isOpen = true;
    this.model = model ? model : {};
  }

  @Mutation
  public close(): void {
    this.isOpen = false;
  }
}
