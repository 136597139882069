import { createModel } from "@/util/ModelUtil";
import { SubscriberStatusType } from "@/enum/SubscriberStatusType";

export default class SubscriberModel {
  constructor(
    public id: number = 0,
    public userId: number = 0,
    public productId: number = 0,
    public subscribeId: number = 0,
    public paymentHistoryId: number = 0,
    public couponUsedFlag: boolean = false,
    public couponId: number = 0,
    public paidFlag: boolean = false,
    public expiredDate: number = 0,
    public refuseCount: number = 0,
    public cardNumber: string = "",
    public cardCompany: string = "",
    public status: SubscriberStatusType = SubscriberStatusType.NONE,
    public createdAt: number = new Date().getTime(),
    public updatedAt: number = new Date().getTime()
  ) {}

  public static create(subscriber?: Subscriber): SubscriberModel {
    return createModel<SubscriberModel>(SubscriberModel, subscriber);
  }
}
