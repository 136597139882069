import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Route } from "vue-router";

@Module({ namespaced: true, name: "history" })
export default class History extends VuexModule {
  public routeList: Route[] = [];

  @Mutation
  public addHistory(route: Route): void {
    if (this.routeList.length > 10) this.routeList.shift();
    this.routeList.push(route);
  }

  public get beforeHistory(): Route | null {
    return this.routeList.length > 1
      ? this.routeList[this.routeList.length - 2]
      : null;
  }
}
