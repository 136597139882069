import { RouterMenuName } from "@/enum/RouterMenuName";
import { menu } from "@/router/menu";
import { HeaderClassType } from "@/enum/HeaderClassType";
import { LayoutType } from "@/enum/LayoutType";
import { RouteConfig } from "vue-router";

export const subscribePage: RouteConfig[] = [
  {
    path: "/subscribe",
    name: RouterMenuName.Subscribe,
    component: menu.subscribe.main,
    meta: {
      headerClass: `${HeaderClassType.My}`,
      layout: LayoutType.NavigationLayout
    }
  }
];
