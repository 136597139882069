import ApiService from "@/service/api/ApiService";
import { catchError, map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import PageModel from "@/service/model/page/PageModel";

class AlarmService extends ApiService {
  /**
   * 사용자 알림 List 조회
   * @param page
   */
  public retrieveAlarmList(page: number): Observable<Page<AlarmFeed>> {
    return this.get<Page<AlarmFeed>>("/alarm/list", { page }, true).pipe(
      map((alarmFeedPage: Page<AlarmFeed>) =>
        PageModel.create<AlarmFeed>(alarmFeedPage)
      ),
      catchError(() => of(new PageModel<AlarmFeed>()))
    );
  }

  /**
   * 사용자 읽지 않은 알림 List 조회
   * @param page
   */
  public retrieveUnreadAlarmList(page: number): Observable<Page<AlarmFeed>> {
    return this.get<Page<AlarmFeed>>("/alarm/list/unread", { page }, true).pipe(
      map((alarmFeedPage: Page<AlarmFeed>) =>
        PageModel.create<AlarmFeed>(alarmFeedPage)
      ),
      catchError(() => of(new PageModel<AlarmFeed>()))
    );
  }

  /**
   * 사용자 알림 전체 읽음 요청
   */
  public updateAlarmFeedListAsRead(): Observable<boolean> {
    return this.put<boolean>("/alarm/allRead", {}, true).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * 사용자 알림 읽음 요청
   */
  public updateAlarmFeedAsRead(id: number): Observable<boolean> {
    return this.put<boolean>("/alarm/read", { id }, true).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * 사용자 알림 전체 삭제
   */
  public deleteAlarmFeedAll(): Observable<boolean> {
    return this.delete<boolean>("/alarm/feed/all", {}, true).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * 사용자가 읽지 않은 알림 수
   */
  public retrieveUnReadAlarmCount(): Observable<number> {
    return this.get<number>("/alarm/unReadCount", {}, true).pipe(
      map((count: number) => count),
      catchError(() => of(0))
    );
  }
}

export const alarmService = new AlarmService();
