import ApiService from "@/service/api/ApiService";
import { catchError, map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import CartModel from "@/service/model/cart/CartModel";

class CartService extends ApiService {
  /**
   * 사용자 카트 리스트 조회
   */
  public retrieveCartList(): Observable<Cart[]> {
    return this.get<Cart[]>("/cart/list", {}, true).pipe(
      map((cartList: Cart[]) => cartList.map(cart => CartModel.create(cart))),
      catchError(() => of([]))
    );
  }

  /**
   * 사용자 카트에 상품 추가
   * @param productId
   */
  public createCart(productId: number): Observable<Cart> {
    return this.post<Cart>("/cart", { productId }, true).pipe(
      map((cart: Cart) => CartModel.create(cart)),
      catchError(() => of(new CartModel()))
    );
  }

  /**
   * 사용자 카트 리스트 제거
   * @param cartIdList
   */
  public deleteCart(cartIdList: number[]): Observable<boolean> {
    return this.delete<boolean>("/cart", { cartIdList }, true).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }
}

export const cartService = new CartService();
