import { createModel } from "@/util/ModelUtil";
import { LangType } from "@/enum/LangType";
import { TestType } from "@/enum/TestType";
import { SubChapterType } from "@/enum/SubChapterType";

export default class SubChapterModel {
  constructor(
    public id: number = 0,
    public chapterId: number = 0,
    public testId: number = 0,
    public surveyId: number = 0,
    public title: string = "",
    public content: string = "",
    public type: SubChapterType = SubChapterType.Content,
    public videoUrl: string = "",
    public learningTime: number = 0,
    public defaultCode: string = "",
    public order: number = -1,
    public langType: LangType = LangType.NONE,
    public testType: TestType = TestType.NONE,
    public isPreview: boolean = false,
    public resourceUrl: string = "",
    public createdAt: number = new Date().getTime(),
    public updatedAt: number = new Date().getTime()
  ) {}

  public static create(subChapter?: SubChapter): SubChapterModel {
    return createModel<SubChapterModel>(SubChapterModel, subChapter);
  }
}
