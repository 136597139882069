import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import ConfirmModel from "@/components/common/modal/model/ConfirmModel";

@Module({ namespaced: true, name: "confirm" })
export default class Confirm extends VuexModule {
  public isAutoClose = true;
  public isOpen = false;
  public model: ConfirmModel = this.emptyConfirm();

  @Mutation
  public open(confirm: ConfirmModel): void {
    this.model = confirm;
    this.isOpen = true;
  }

  @Mutation
  public close(): void {
    this.isOpen = false;
  }

  @Mutation
  public setIsAutoClose(isAutoClose: boolean): void {
    this.isAutoClose = isAutoClose;
  }

  public emptyConfirm(): ConfirmModel {
    return {
      content: "content"
    };
  }
}
