import { createModel } from "@/util/ModelUtil";
import OrganizationLogoModel from "@/service/model/organization/OrganizationLogoModel";

export default class CourseMapOrganizationLogoModel {
  constructor(
    public id: number = 0,
    public courseId: number = 0,
    public organizationLogo: OrganizationLogo = new OrganizationLogoModel(),
    public order: number = 0,
    public createdAt: number = new Date().getTime(),
    public updatedAt: number = new Date().getTime()
  ) {}

  public static create(
    courseMapOrganizationLogo?: CourseMapOrganizationLogo
  ): CourseMapOrganizationLogoModel {
    return createModel<CourseMapOrganizationLogoModel>(
      CourseMapOrganizationLogoModel,
      courseMapOrganizationLogo
    );
  }
}
