import { createModel } from "@/util/ModelUtil";

export default class OrganizationScoreWeightModel {
  constructor(
    public id: number = 0,
    public organizationId: number = 0,
    public progress: number = 0,
    public beforeTest: number = 0,
    public afterTest: number = 0,
    public training: number = 0,
    public completionScore: number = 0,
    public createdAt: number = 0,
    public updatedAt: number = 0
  ) {}

  public static create(
    organizationScoreWeight?: OrganizationScoreWeight
  ): OrganizationScoreWeightModel {
    return createModel<OrganizationScoreWeightModel>(
      OrganizationScoreWeightModel,
      organizationScoreWeight
    );
  }
}
