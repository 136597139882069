import moment, { unitOfTime } from "moment";
import { isEmptyString } from "@/util/StringUtil";
const formattedDate = (
  date: Date | string | number,
  format = "YYYY-MM-DD"
): string => {
  return moment(date).format(format);
};

const formattedDayOfWeek = (date: Date | string | number): string => {
  return moment(date).format("YYYY년 MM월 DD일(ddd)");
};

const formattedTimeBySecond = (
  second: number,
  format = "HH:mm",
  removeZeroHourFormat = ""
): string => {
  return moment
    .utc(moment.duration(second, "seconds").asMilliseconds())
    .format(
      !isEmptyString(removeZeroHourFormat) && second < 3600
        ? removeZeroHourFormat
        : format
    );
};

const formattedMinutesTimeBySecond = (second: number): string => {
  const min = Math.floor(second / 60);
  const sec = Math.ceil(second % 60);
  return `${min >= 10 ? min : "0" + min}:${sec >= 10 ? sec : "0" + sec}`;
};

const dayDifference = (date: Date | number): number => {
  return moment().diff(date, "days");
};

const makeDateParam = (startTime = "", endTime = "") => {
  const requestParams: { startTime?: string; endTime?: string } = {};

  if (!isEmptyString(startTime)) {
    requestParams.startTime = startTime;
  }
  if (!isEmptyString(endTime)) {
    requestParams.endTime = endTime;
  }

  return requestParams;
};

const isCurrentDateBefore = (
  date: Date | number | string,
  granularity?: unitOfTime.StartOf
): boolean => {
  return isEmptyString(granularity)
    ? moment(new Date().getTime()).isBefore(date)
    : moment(new Date().getTime()).isBefore(
        date,
        granularity as unitOfTime.StartOf
      );
};

const isCurrentDateAfter = (
  date: Date | number | string,
  granularity?: unitOfTime.StartOf
): boolean => {
  return isEmptyString(granularity)
    ? moment(new Date().getTime()).isAfter(date)
    : moment(new Date().getTime()).isAfter(
        date,
        granularity as unitOfTime.StartOf
      );
};

const isCurrentDateBetween = (
  startDate: Date | number | string,
  endDate: Date | number | string
): boolean => {
  return moment(new Date().getTime()).isBetween(
    startDate,
    endDate,
    "day",
    "[]"
  );
};

const isDateAfter = (
  firstDate: Date | number,
  secondDate: Date | number
): boolean => {
  return moment(firstDate).isAfter(secondDate);
};

export {
  formattedDate,
  dayDifference,
  makeDateParam,
  isCurrentDateBefore,
  isCurrentDateAfter,
  isCurrentDateBetween,
  formattedDayOfWeek,
  isDateAfter,
  formattedTimeBySecond,
  formattedMinutesTimeBySecond
};
