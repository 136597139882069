import { createModel } from "@/util/ModelUtil";
import { ProductType } from "@/enum/ProductType";
import { SubscribeType } from "@/enum/SubscribeType";

export default class ProductModel {
  constructor(
    public id: number = 0,
    public title: string = "",
    public price: number = 0,
    public type: ProductType = ProductType.LECTURE,
    public mapId: number = 0,
    public image: string = "",
    public periodDay: number = 0,
    public expose: boolean = false,
    public createdAt: number = new Date().getTime(),
    public updatedAt: number = new Date().getTime(),
    public promotionList: ProductPromotion[] = [],
    public content: string = ""
  ) {}

  public static create(product?: Product): ProductModel {
    return createModel<ProductModel>(ProductModel, product);
  }

  public get discountPrice(): number {
    return this.isProductPromotion
      ? this.price - this.promotionList[0].price
      : 0;
  }

  public discountPriceByType(subscribeType: SubscribeType | null): number {
    const promotion = this.getPromotionByType(subscribeType);
    return promotion ? this.price - promotion.price : this.price;
  }

  public get isProductPromotion(): boolean {
    return this.promotionList && this.promotionList.length > 0;
  }

  public get promotion(): null | ProductPromotion {
    return this.promotionList[0];
  }

  public get paymentPrice(): number {
    return this.isProductPromotion
      ? (this.promotion as ProductPromotion).price
      : this.price;
  }

  public paymentPriceByType(subscribeType: SubscribeType | null): number {
    const promotion = this.getPromotionByType(subscribeType);
    return promotion ? promotion.price : this.price;
  }

  public isProductPromotionByType(
    subscribeType: SubscribeType | null
  ): boolean {
    return this.getPromotionByType(subscribeType) !== null;
  }

  public getPromotionByType(
    subscribeType: SubscribeType | null
  ): null | ProductPromotion {
    const typeList: (string | null)[] = [];
    if (SubscribeType.PRO === subscribeType)
      typeList.push(SubscribeType.PRO.toString());
    if (SubscribeType.STANDARD === subscribeType)
      typeList.push(SubscribeType.STANDARD.toString());
    if (SubscribeType.BASIC === subscribeType)
      typeList.push(SubscribeType.BASIC.toString());

    const promotionList = this.promotionList.filter(
      promotion =>
        typeList.includes(promotion.subscribeType) || !promotion.subscribeType
    );

    if (promotionList.length === 0) return null;

    return promotionList.sort(
      (promotionA, promotionB) => promotionA.price - promotionB.price
    )[0];
  }
}
