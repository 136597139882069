import ApiService from "@/service/api/ApiService";
import { catchError, map, switchMap } from "rxjs/operators";
import { Observable, of, throwError } from "rxjs";
import OrganizationScoreWeightModel from "@/service/model/organization/OrganizationScoreWeightModel";
import OrganizationModel from "@/service/model/organization/OrganizationModel";

class OrganizationService extends ApiService {
  /**
   * 기업 문의 추가
   * @param inquiry
   */
  public createOrganizationInquiry(
    inquiry: OrganizationInquiry
  ): Observable<boolean> {
    return this.post<boolean>("/organization/inquiry", inquiry).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * 기업의 점수 가중치
   */
  public retrieveOrganizationScoreWeight(): Observable<
    OrganizationScoreWeight
  > {
    return this.get<OrganizationScoreWeight>(
      "/organization/scoreWeight",
      {},
      true
    ).pipe(
      map((organizationScoreWeight: OrganizationScoreWeight) =>
        OrganizationScoreWeightModel.create(organizationScoreWeight)
      ),
      catchError(() => of(new OrganizationScoreWeightModel()))
    );
  }

  /**
   * 기업의 점수 수정
   * @param progress
   * @param beforeTest
   * @param afterTest
   * @param training
   * @param completionScore
   */
  public updateOrganizationScoreWeight(
    progress: number,
    beforeTest: number,
    afterTest: number,
    training: number,
    completionScore: number
  ): Observable<boolean> {
    return this.put<boolean>(
      "/organization/scoreWeight",
      { progress, beforeTest, afterTest, training, completionScore },
      true
    ).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * HostName을 통한 기업 조회
   * @param subDomain
   */
  public retrieveByHostname(subDomain: string): Observable<Organization> {
    return this.get<Organization>("/organization/subDomain", {
      subDomain
    }).pipe(
      switchMap((organization: Organization) => {
        return organization === null ? throwError(null) : of(organization);
      }),
      map((organization: Organization) => {
        return OrganizationModel.create(organization);
      })
    );
  }

  /**
   * 기업 코드 확인
   * @param id
   * @param code
   */
  public isVerificationCode(id: number, code: string): Observable<boolean> {
    return this.get<boolean>("/organization/check/code", { id, code }).pipe(
      map((isVerificationCode: boolean) => isVerificationCode)
    );
  }
}

export const organizationService = new OrganizationService();
