import { RouterMenuName } from "@/enum/RouterMenuName";
import { menu } from "@/router/menu";
import { HeaderClassType } from "@/enum/HeaderClassType";
import { LayoutType } from "@/enum/LayoutType";
import { RouteConfig } from "vue-router";
import RouterMeta from "@/router/model/RouterMeta";

const meta: RouterMeta = {
  headerClass: HeaderClassType.My,
  layout: LayoutType.FormLayout,
  guard: {
    login: false
  }
};

const auth: Array<RouteConfig> = [
  {
    path: "/auth/user/email",
    name: RouterMenuName.AuthUserEmail,
    component: menu.auth.user.email,
    meta: { ...meta, isNormalUser: true }
  },
  {
    path: "/auth/user/social",
    name: RouterMenuName.AuthUserSocial,
    component: menu.auth.user.social,
    meta: { ...meta, isNormalUser: true }
  },
  {
    path: "/auth/user/terms",
    name: RouterMenuName.AuthUserTerms,
    component: menu.auth.user.terms,
    meta: { ...meta, isNormalUser: true }
  },
  {
    path: "/auth/user/passwordChange",
    component: menu.auth.user.passwordChange,
    name: RouterMenuName.AuthUserPasswordChange,
    meta
  },
  {
    path: "/auth/payment",
    component: menu.auth.payment.main,
    name: RouterMenuName.AuthPayment,
    meta: { ...meta, layout: LayoutType.NoneLayout }
  },
  {
    path: "/organization/sso/auth",
    component: menu.auth.user.organization,
    name: RouterMenuName.AuthOrganization,
    meta: { ...meta, isNormalUser: true }
  }
];

export default auth;
