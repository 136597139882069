import { RouterMenuName } from "@/enum/RouterMenuName";
import { menu } from "@/router/menu";
import { HeaderClassType } from "@/enum/HeaderClassType";
import { LayoutType } from "@/enum/LayoutType";
import { RouteConfig } from "vue-router";

export const course: Array<RouteConfig> = [
  {
    path: "/course",
    name: RouterMenuName.CourseMain,
    component: menu.course.main,
    meta: {
      headerClass: HeaderClassType.My,
      layout: LayoutType.NavigationLayout
    }
  },
  {
    path: "/course/:id",
    name: RouterMenuName.CourseDetail,
    component: menu.course.detail,
    meta: {
      headerClass: HeaderClassType.My,
      layout: LayoutType.NavigationLayout
    }
  }
];
