import { createModel } from "@/util/ModelUtil";

export default class MyCourseCardModel {
  constructor(
    public courseId: number = 0,
    public title: string = "",
    public subTitle: string = "",
    public cardImageUrl: string = "",
    public postCount: number = 0,
    public bookmarkCount: number = 0,
    public memoCount: number = 0,
    public unansweredQuestions: number = 0
  ) {}

  public static create(myCourseCard?: MyCourseCard): MyCourseCardModel {
    return createModel<MyCourseCardModel>(MyCourseCardModel, myCourseCard);
  }
}
