import { createModel } from "@/util/ModelUtil";
import { PaymentStatus } from "@/enum/PaymentStatus";
import { PaymentOption } from "@/enum/PaymentOption";
import { PaymentErrorType } from "@/enum/PaymentErrorType";

export default class PaymentHistoryModel {
  constructor(
    public id: number = 0,
    public paymentOption: PaymentOption = PaymentOption.CreditCard,
    public totalPrice: number = 0,
    public price: number = 0,
    public status: PaymentStatus = PaymentStatus.NONE,
    public orderName: string = "",
    public orderId: string = "",
    public userId: number = 0,
    public rejectReason: string = "",
    public refundReason: string = "",
    public createdAt: number = new Date().getTime(),
    public updatedAt: number = new Date().getTime(),
    public requestedAt: number = 0,
    public approvedAt: number = 0,
    public paymentMapProductList: PaymentMapProduct[] = [],
    public errorType: PaymentErrorType = PaymentErrorType.NONE,
    public cardCompany: string = "",
    public cardNumber: string = "",
    public cardType: string = "",
    public cardOwnerType: string = "",
    public cardReceiptUrl: string = "",
    public couponId: number = 0,
    public userMapCouponId: number = 0,
    public couponDiscountPrice: number = 0,
    public couponTitle: string = "",
    public refundList: Array<Refund> = [],
    public specialTrackPaymentFlag: boolean = false
  ) {}

  public get paymentMethod(): string {
    if (this.paymentOption === PaymentOption.CreditCard) {
      return `${this.cardType}${this.paymentOption}(${this.cardOwnerType}) ${this.cardCompany} ${this.cardNumber}`;
    }
    return "";
  }

  public get paymentStatus(): string {
    switch (this.status) {
      case PaymentStatus.READY:
        return "준비 완료";
      case PaymentStatus.IN_PROGRESS:
        return "진행 중";
      case PaymentStatus.WAITING_FOR_DEPOSIT:
        return "입금 대기 중";
      case PaymentStatus.DONE:
        return "결제 완료";
      case PaymentStatus.CANCELED:
        return "전액 취소";
      case PaymentStatus.PARTIAL_CANCELED:
        return "부분 취소";
      case PaymentStatus.ABORTED:
        return "결제 실패";
      case PaymentStatus.EXPIRED:
        return "결제 유효시간 초과";
      default:
        return "";
    }
  }

  public static create(paymentHistory?: PaymentHistory): PaymentHistoryModel {
    return createModel<PaymentHistoryModel>(
      PaymentHistoryModel,
      paymentHistory
    );
  }
}
