
import { Component, Watch } from "vue-property-decorator";
import { InjectModule } from "@/decorators/InjectModule";
import Organization from "@/store/module/Organization";
import Base from "@/Base";

@Component({
  components: {
    OrganizationHome: () =>
      import(
        /* webpackChunkName: "OrganizationHome" */ "@/components/home/organization/OrganizationHome.vue"
      ),
    MainHome: () =>
      import(
        /* webpackChunkName: "MainHome" */ "@/components/home/main/MainHome.vue"
      )
  }
})
export default class Home extends Base {
  @InjectModule(Organization)
  public organization$!: Organization;

  public mounted(): void {
    this.isSessionExpireWatch(this.$route.query.isSessionExpire as string);
    this.isDuplicatedLoginWatch(this.$route.query.isDuplicatedLogin as string);
  }

  @Watch("$route.query.isSessionExpire")
  private isSessionExpireWatch(isSessionExpire: string) {
    if (isSessionExpire) {
      this.alert$.open({
        text: "세션이 만료되었습니다.\n다시 로그인해주시기 바랍니다.",
        confirm: { callback: () => this.$router.replace({ query: {} }) }
      });
    }
  }

  @Watch("$route.query.isDuplicatedLogin")
  private isDuplicatedLoginWatch(isDuplicatedLogin: string) {
    if (isDuplicatedLogin) {
      this.alert$.open({
        text: "다른 기기에서 사용되었습니다.\n다시 로그인해주시기 바랍니다.",
        confirm: { callback: () => this.$router.replace({ query: {} }) }
      });
    }
  }
}
