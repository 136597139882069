import { createModel } from "@/util/ModelUtil";
import { UserType } from "@/enum/UserType";

export default class PostModel {
  constructor(
    public id: number = 0,
    public userId: number = 0,
    public courseId: number = 0,
    public lectureId: number = 0,
    public chapterId: number = 0,
    public subChapterId: number = 0,
    public courseTitle: string = "",
    public exposureFlag: boolean = false,
    public communityCategoryMapOrgId: number = 0,
    public categoryType: string = "",
    public categoryBgColor: string = "",
    public categoryColor: string = "",
    public categoryTitle: string = "",
    public chapterTitle: string = "",
    public subChapterTitle: string = "",
    public subChapterType: string = "",
    public postId: number = 0,
    public type: string = "",
    public title: string = "",
    public content: string = "",
    public viewCount: number = 0,
    public likeCount: number = 0,
    public answerCount: number = 0,
    public commentCount: number = 0,
    public tagList: Tag[] = [],
    public userNickName: string = "",
    public userProfileImageUrl: string = "",
    public userType: UserType = UserType.NORMAL,
    public commentList: PostComment[] = [],
    public isBookmarkPost: boolean = false,
    public isLikePost: boolean = false,
    public isCanTakeLecture: boolean = false,
    public readonlyFlag: boolean = false,
    public fixedAt: number = 0,
    public createdAt: number = new Date().getTime(),
    public updatedAt: number = new Date().getTime(),
    //  Todo PostAnswer DTO 추가 후 제거
    public isLikePostAnswer: boolean = false
  ) {}

  public static create(post?: Post): PostModel {
    return createModel<PostModel>(PostModel, post);
  }
}
