import { createModel } from "@/util/ModelUtil";

export default class MySubChapterModel {
  constructor(
    public id: number = 0,
    public userId: number = 0,
    public lectureId: number = 0,
    public chapterId: number = 0,
    public subChapterId: number = 0,
    public completedFlag: boolean = false,
    public lastCode: string = "",
    public createdAt: number = new Date().getTime(),
    public updatedAt: number = new Date().getTime()
  ) {}

  public static create(mySubChapter?: MySubChapter): MySubChapterModel {
    return createModel<MySubChapterModel>(MySubChapterModel, mySubChapter);
  }
}
