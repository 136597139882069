import { createModel } from "@/util/ModelUtil";
import { CourseType } from "@/enum/CourseType";
import UserModel from "@/service/model/user/UserModel";

export default class CourseModel {
  constructor(
    public id: number = 0,
    public userId: number = 0,
    public user: User = new UserModel(),
    public title: string = "",
    public subTitle: string = "",
    public type: CourseType | string = CourseType.E_LEARNING,
    public level: number = 0,
    public whatYouLearn: Array<string> = [],
    public requirement: Array<string> = [],
    public target: Array<string> = [],
    public description: string = "",
    public inclusion: Array<string> = [],
    public bannerImageUrl: string = "",
    public cardImageUrl: string = "",
    public wishlistCount: number = 0,
    public reviewCount: number = 0,
    public reviewRating: number = 0,
    public exposure: boolean = false,
    public cardInfo: string = "",
    public createdAt: number = new Date().getTime(),
    public updatedAt: number = new Date().getTime(),
    public chapterList: Array<string> = [],
    public lecturerList: Lecturer[] = []
  ) {}

  public static create(course?: Course): CourseModel {
    return createModel<CourseModel>(CourseModel, course);
  }
}
