import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse
} from "axios";
import Response from "@/service/dto/Response";
import { router } from "@/router";
import { RouterMenuName } from "@/enum/RouterMenuName";
import { removeCookie } from "@/util/CookieUtil";
import { CookieType } from "@/enum/CookieType";

/* eslint-disable  @typescript-eslint/no-explicit-any */
class HttpConnector {
  public connector: AxiosInstance = axios.create();

  public setConfig(config: AxiosRequestConfig) {
    this.connector = axios.create(config);
    this.setInterceptors();
  }

  // TODO 필요없을시 추후 삭제
  private setInterceptors() {
    this.connector.interceptors.request.use(
      (value: AxiosRequestConfig) => {
        return value;
      },
      (error: AxiosError) => {
        return Promise.reject(error);
      }
    );

    this.connector.interceptors.response.use(
      (value: AxiosResponse<{ data: Response } | any>) => {
        this.handleGlobalError(value.data);
        return value.data;
      },
      (error: AxiosError) => {
        return Promise.reject(error);
      }
    );
  }

  private handleGlobalError(data: Response): void {
    if (
      data.code === 3020 &&
      !(
        router.currentRoute.name === RouterMenuName.Home &&
        !!(router.currentRoute.query.isSessionExpire as string)
      )
    ) {
      removeCookie(CookieType.AccessToken);
      router.replace({
        name: RouterMenuName.Home,
        query: { isSessionExpire: "true" }
      });
    } else if (
      data.code === 4010 &&
      !(
        router.currentRoute.name === RouterMenuName.Home &&
        !!(router.currentRoute.query.isDuplicatedLogin as string)
      )
    ) {
      removeCookie(CookieType.AccessToken);
      router.replace({
        name: RouterMenuName.Home,
        query: { isDuplicatedLogin: "true" }
      });
    }
  }
}

export const connector = new HttpConnector();
