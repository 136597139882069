import ApiService from "@/service/api/ApiService";
import { catchError, map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import MyLectureModel from "@/service/model/myLecture/MyLectureModel";
import MyLectureCardModel from "@/service/model/myLecture/MyLectureCardModel";

class MyLectureService extends ApiService {
  /**
   * 내 강의 상세
   * @param lectureId
   */
  public retrieveMyLecture(lectureId: number): Observable<MyLectureModel> {
    return this.get<MyLecture>(
      "/myLecture",
      { lectureId: lectureId },
      true
    ).pipe(
      map((myLecture: MyLecture) => MyLectureModel.create(myLecture)),
      catchError(() => of(new MyLectureModel()))
    );
  }

  /**
   * 내 강의
   * @param lectureId
   */
  public retrieveSimpleMyLecture(
    lectureId: number
  ): Observable<MyLectureModel> {
    return this.get<MyLecture>(
      "/myLecture/simple",
      { lectureId: lectureId },
      true
    ).pipe(
      map((myLecture: MyLecture) => MyLectureModel.create(myLecture)),
      catchError(() => of(new MyLectureModel()))
    );
  }

  /**
   * 내 강의 상세 - Manager 용
   * @param userId
   * @param lectureId
   */
  public retrieveMyLectureForManager(
    userId: number,
    lectureId: number
  ): Observable<MyLectureModel> {
    return this.get<MyLecture>(
      "/myLecture/manager",
      { lectureId, userId },
      true
    ).pipe(
      map((myLecture: MyLecture) => MyLectureModel.create(myLecture)),
      catchError(() => of(new MyLectureModel()))
    );
  }

  /**
   * 진행 중인 내 강의
   */
  public retrieveMyLectureCardList(): Observable<MyLectureCard[]> {
    return this.get<MyLectureCard[]>("/myLecture/list/card", {}, true).pipe(
      map((myLectureCardList: MyLectureCard[]) =>
        myLectureCardList.map(myLectureCard =>
          MyLectureCardModel.create(myLectureCard)
        )
      ),
      catchError(() => of([]))
    );
  }

  /**
   * 코스별 진행 중인 내 강의
   * @param courseId
   */
  public retrieveMyLectureCardByCourseId(
    courseId: number
  ): Observable<MyLectureCard[]> {
    return this.get<MyLectureCard[]>(
      "/myLecture/list/card/course",
      { courseId },
      true
    ).pipe(
      map((myLectureCardList: MyLectureCard[]) =>
        myLectureCardList.map(myLectureCard =>
          MyLectureCardModel.create(myLectureCard)
        )
      ),
      catchError(() => of([]))
    );
  }

  /**
   * 구독자 구독 강의 데이터 싱크
   * @param courseId
   */
  public syncSubscribeLectureByCourseId(courseId: number): Observable<boolean> {
    return this.post<boolean>("/myLecture/subscriber", { courseId }, true).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * 구독자 구독 강의 데이터 싱크
   * @param courseId
   * @param organizationId
   */
  public syncLectureByCourseIdAndOrganizationId(
    courseId: number,
    organizationId: number
  ): Observable<boolean> {
    return this.post<boolean>(
      "/myLecture/organization",
      { courseId, organizationId },
      true
    ).pipe(map((isSuccess: boolean) => isSuccess));
  }

  /**
   * 티켓 데이터 싱크
   * @param lectureId
   */
  public syncLectureByCourseIdAndTicket(
    lectureId: number
  ): Observable<boolean> {
    return this.post<boolean>("/ticket", { lectureId }, true).pipe(
      map((isSuccess: boolean) => isSuccess)
    );
  }

  /**
   * 수강 가능한 MyLecture 리스트 조회
   * @param courseId
   * @param organizationId
   */
  public retrieveAvailableMyLecture(
    courseId: number,
    organizationId: number
  ): Observable<MyLecture[]> {
    return this.get<MyLecture[]>(
      "/myLecture/organization/available",
      { courseId, organizationId },
      true
    ).pipe(
      map((myLectureList: MyLecture[]) =>
        myLectureList.map(myLecture => MyLectureModel.create(myLecture))
      )
    );
  }

  /**
   * 수료 날짜 조회
   * @param id
   * @param progress
   */
  public retrieveCompletionDate(
    id: number,
    progress: number
  ): Observable<Date> {
    return this.get<Date>(
      "/myLecture/completionDate",
      { id, progress },
      true
    ).pipe(map((completionDate: Date) => completionDate));
  }
}

export const myLectureService = new MyLectureService();
