import { createModel } from "@/util/ModelUtil";
import { isCurrentDateBefore, isCurrentDateBetween } from "@/util/DateUtil";

export default class MyLectureCardModel {
  constructor(
    public id: number = 0,
    public userId: number = 0,
    public courseId: number = 0,
    public lectureId: number = 0,
    public organizationId: number = 0,
    public lectureTitle: string = "",
    public courseSubTitle: string = "",
    public cardImageUrl: string = "",
    public progress: number = 0,
    public lectureCompletionRate: number = 80,
    public completedFlag: boolean = false,
    public postCount: number = 0,
    public bookmarkCount: number = 0,
    public myLectureStartedAt: number = 0,
    public myLectureEndedAt: number = 0,
    public lectureStartedAt: number = 0,
    public lectureEndedAt: number = 0
  ) {}

  public static create(myLectureCard?: MyLectureCard): MyLectureCardModel {
    return createModel<MyLectureCardModel>(MyLectureCardModel, myLectureCard);
  }

  public get isToBeOpenedLecture(): boolean {
    return !this.isFinishedLecture && !this.isAvailableLecture;
  }

  public get isFinishedLecture(): boolean {
    return !isCurrentDateBefore(this.endedAt);
  }

  public get isAvailableLecture(): boolean {
    return isCurrentDateBetween(this.startedAt, this.endedAt);
  }

  public get startedAt(): number {
    return this.lectureStartedAt > this.myLectureStartedAt
      ? this.lectureStartedAt
      : this.myLectureStartedAt;
  }

  public get endedAt(): number {
    return this.lectureEndedAt > this.myLectureEndedAt
      ? this.myLectureEndedAt
      : this.lectureEndedAt;
  }
}
