import { createModel } from "@/util/ModelUtil";

export default class OrganizationLogoModel {
  constructor(
    public id: number = 0,
    public logo: string = "",
    public createdAt: number = new Date().getTime(),
    public updatedAt: number = new Date().getTime()
  ) {}

  public static create(
    organizationLogo?: OrganizationLogo
  ): OrganizationLogoModel {
    return createModel<OrganizationLogoModel>(
      OrganizationLogoModel,
      organizationLogo
    );
  }
}
