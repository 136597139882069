import { createModel } from "@/util/ModelUtil";
import ProductModel from "@/service/model/product/ProductModel";

export default class CartModel {
  constructor(
    public id: number = 0,
    public userId: number = 0,
    public product: Product = new ProductModel(),
    public isSelected: boolean = false,
    public createdAt: number = new Date().getTime(),
    public updatedAt: number = new Date().getTime()
  ) {}

  public static create(cart?: Cart): CartModel {
    return createModel<CartModel>(CartModel, cart);
  }
}
