import { createModel } from "@/util/ModelUtil";
import CouponModel from "@/service/model/coupon/CouponModel";
import PaymentHistoryModel from "@/service/model/paymentHistory/PaymentHistoryModel";
import SubscriberModel from "@/service/model/subscribe/SubscriberModel";

export default class CouponMapUserModel {
  constructor(
    public id: number = 0,
    public userId: number = 0,
    public coupon: Coupon = new CouponModel(),
    public createdAt: number = new Date().getTime(),
    public updatedAt: number = new Date().getTime(),
    public endedAt: number = 0,
    public usedAt: number = 0,
    public subscriber: Subscriber = new SubscriberModel(),
    public paymentHistory: PaymentHistory = new PaymentHistoryModel(),
    public availableCouponFlag: boolean = false
  ) {}

  public static create(couponMapUser?: CouponMapUser): CouponMapUserModel {
    return createModel<CouponMapUserModel>(CouponMapUserModel, couponMapUser);
  }
}
