import ApiService from "@/service/api/ApiService";
import { catchError, map } from "rxjs/operators";
import { Observable, of, throwError } from "rxjs";
import UserModel from "@/service/model/user/UserModel";
import UserSignUp from "@/service/dto/user/UserSignUp";
import { UserFullExtraInfo } from "@/views/my/profile/model/UserFullExtraInfo";
import Response from "@/service/dto/Response";

class UserService extends ApiService {
  /**
   * 로그인
   * @param email
   * @param password
   * @param plainTextPassword
   * @param organizationId
   */
  public signIn(
    email: string,
    password: string,
    plainTextPassword: string,
    organizationId: number
  ): Observable<string> {
    return this.post<string>("/user/signin", {
      email,
      password,
      plainTextPassword,
      organizationId
    }).pipe(map((token: string) => token));
  }

  /**
   * 사용자 정보 조회
   */
  public retrieveUser(isFirstLogin?: boolean): Observable<UserModel> {
    return this.get<User>("/user", { isFirstLogin }, true).pipe(
      map((user: User) => UserModel.create(user)),
      catchError(() => of(new UserModel()))
    );
  }

  /**
   * 일반 유저 회원가입
   * @param singUpFormData
   */
  public createUser(singUpFormData: FormData): Observable<boolean> {
    return this.post<boolean>("/user/signup", singUpFormData, false, true).pipe(
      map((isSuccess: boolean) => isSuccess)
    );
  }

  /**
   * organizationCode 코드 필수임
   * @param singUpInfo
   */
  public createOrgUser(singUpInfo: UserSignUp): Observable<UserModel> {
    return this.post<User>("/user/signup/org", singUpInfo).pipe(
      map((user: User) => UserModel.create(user))
    );
  }

  /**
   * 이메일 중복 확인
   * @param organizationId
   * @param email
   */
  public isAvailableEmail(
    organizationId: number,
    email: string
  ): Observable<boolean> {
    return this.get<boolean>("/user/check/email", {
      organizationId,
      email
    }).pipe(map((isAvailableEmail: boolean) => isAvailableEmail));
  }

  /**
   * 닉네임 중복 확인
   * @param nickName
   */
  public isDuplicatedNickName(nickName: string): Observable<string> {
    return this.get<string>("/user/check/nickName", { nickName });
  }

  /**
   * 사용자 Marketing 동의 정보 수정
   * @param smsMarketing
   * @param emailMarketing
   */
  public updateUserMarketing(
    smsMarketing: boolean,
    emailMarketing: boolean
  ): Observable<boolean> {
    return this.put<boolean>(
      "/user/messengerSettings",
      {
        smsMarketingTermsFlag: smsMarketing,
        emailMarketingTermsFlag: emailMarketing
      },
      true
    ).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * 사용자 정보 수정
   * @param userFullExtraInfo
   */
  public updateUserInfo(
    userFullExtraInfo: UserFullExtraInfo
  ): Observable<boolean> {
    return this.put<boolean>("/user", userFullExtraInfo, true).pipe(
      map((isSuccess: boolean) => isSuccess)
    );
  }

  /**
   * 사용자 탈퇴
   */
  public updateLeaveUser(id: number): Observable<boolean> {
    return this.put<boolean>("/user/leave", { id }, true).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * 사용자 비밀번호 변경
   * @param password
   * @param newPassword
   */
  public updatePassword(
    password: string,
    newPassword: string
  ): Observable<boolean | null> {
    return this.put<boolean>(
      "/user/password",
      { password, newPassword },
      true
    ).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError((result: Response) => of(result.code === 403 ? false : null))
    );
  }

  /**
   * 이전 사용자 데이터 변경
   * @param email
   * @param newEmail
   * @param password
   */
  public updateFormerUser(
    email: string,
    newEmail: string,
    password: string
  ): Observable<boolean> {
    return this.put<boolean>("/user/signup/formerUser", {
      email,
      newEmail,
      password
    }).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * 토큰 인증을 이용한 사용자 비밀번호 변경
   * @param id
   * @param newPassword
   * @param token
   */
  public updatePasswordByToken(
    id: number,
    newPassword: string,
    token: string
  ): Observable<boolean> {
    return this.put<boolean>("/user/password/token", {
      id,
      newPassword,
      token
    }).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * 사용자 비밀번호 변경 후 90일 경과 확인
   */
  public checkPasswordChangingState(): Observable<Response> {
    return this.get<Response>("/user/password/expired", {}, true).pipe(
      map((result: Response) => result)
    );
  }

  /**
   * 사용자 비밀번호 다음에 변경 클릭
   */
  public updatePasswordNextTime(): Observable<boolean> {
    return this.put<boolean>("/user/passwordClick", {}, true).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * 사용자 전화번호/주소 데잍 ㅓ수정
   * @param phone
   * @param address
   */
  public updatePhoneAndAddress(
    phone: string,
    address: string
  ): Observable<User> {
    return this.put<User>(
      "/user/addressAndPhone",
      {
        phone,
        address
      },
      true
    ).pipe(
      map((user: User) => UserModel.create(user)),
      catchError(() => of(new UserModel()))
    );
  }

  /**
   * K-디지털크레딧 사용자 이메일 OTP 인증 체크
   */
  public retrieveIsAuthenticatedUser(): Observable<boolean> {
    return this.get<boolean>(
      "/user/check/kDigitalCredit/emailOTP",
      {},
      true
    ).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * K-디지털크레딧 사용자 이메일 OTP 인증
   */
  public updateAuthKDigitalCreditEmailOTP(
    token: string,
    lectureId: number,
    chapterId: number,
    subChapterId: number,
    subChapterType: string
  ): Observable<boolean> {
    return this.put<boolean>(
      "user/auth/kDigitalCredit/emailOTP",
      {
        token,
        lectureId,
        chapterId,
        subChapterId,
        subChapterType
      },
      true
    ).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * 휴면 계정 상태 변경
   */
  public updateUserStatusSleepToNormal(): Observable<boolean> {
    return this.put<boolean>("/user/dormant", undefined, true).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * 휴대폰 인증번호 발급
   */
  public getUserPhoneAuth(
    phone: string,
    userAuthType: string
  ): Observable<Response> {
    return this.post<Response>(
      "/user/phoneAuth",
      { phone, userAuthType },
      true
    ).pipe(
      map((response: Response) => response),
      catchError((result: Response) => throwError(result))
    );
  }

  /**
   * 휴대폰 인증번호 확인
   */
  public checkPhoneAuthMatch(
    phone: string,
    userAuthType: string,
    token: string
  ): Observable<Response> {
    return this.put<Response>(
      "/user/phoneAuthMatch",
      { phone, userAuthType, token },
      true
    ).pipe(
      map((response: Response) => response),
      catchError((result: Response) => throwError(result))
    );
  }

  /**
   * 휴대폰 저장
   */
  public saveCellPhoneNumber(phone: string): Observable<boolean> {
    return this.put<boolean>("/user/phone", { phone }, true).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * 사용자 정보 조회
   */
  public organizationSSOSignIn(
    organizationId: number,
    authorizationCode: string | null,
    qq: string | null,
    ts: string | null,
    sno: string | null
  ): Observable<{ courseId: number; token: string }> {
    return this.post<{ courseId: number; token: string }>(
      "/organization/sso/auth",
      { organizationId, authorizationCode, qq, ts, sno },
      true
    ).pipe(map((data: { courseId: number; token: string }) => data));
  }
}

export const userService = new UserService();
