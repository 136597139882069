import { CookieType } from "@/enum/CookieType";
import Cookies from "js-cookie";

const setCookie = (
  cookieType: CookieType,
  value: string | object,
  expires = 0
): void => {
  if (expires !== 0) {
    Cookies.set(cookieType, value);
  } else {
    Cookies.set(cookieType, value, { expires });
  }
};

const getCookie = (cookieType: CookieType): string | undefined => {
  return Cookies.get(cookieType);
};

const removeCookie = (cookieType: CookieType): void => {
  return Cookies.remove(cookieType);
};

export { setCookie, getCookie, removeCookie };
