import { RouteConfig } from "vue-router";
import { RouterMenuName } from "@/enum/RouterMenuName";
import { menu } from "@/router/menu";
import { HeaderClassType } from "@/enum/HeaderClassType";
import { LayoutType } from "@/enum/LayoutType";
import RouterMeta from "@/router/model/RouterMeta";

const meta: RouterMeta = {
  headerClass: `${HeaderClassType.My}`,
  layout: LayoutType.FormLayout
};

const customerCenter: RouteConfig = {
  path: "/customerCenter",
  component: menu.customerCenter.parent,
  children: [
    {
      path: "faq",
      component: menu.customerCenter.faq.main,
      name: RouterMenuName.CustomerCenterFAQ,
      meta: { ...meta }
    },
    {
      path: "write",
      name: RouterMenuName.CustomerCenterWrite,
      component: menu.customerCenter.write.main,
      meta: { ...meta, guard: { login: true } }
    },
    {
      path: "myQnA",
      name: RouterMenuName.CustomerCenterMyQNA,
      component: menu.customerCenter.myQnA.main,
      meta: { ...meta, guard: { login: true } }
    }
  ]
};

export const customerCenterPage: RouteConfig = customerCenter;
