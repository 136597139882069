import ApiService from "@/service/api/ApiService";
import { catchError, map } from "rxjs/operators";
import CourseModel from "@/service/model/course/CourseModel";
import { Observable, of } from "rxjs";
import CoursePromotionsModel from "@/service/model/course/CoursePromotionsModel";
import { CoursePromotionType } from "@/enum/CoursePromotionType";
import CourseMapOrganizationLogoModel from "@/service/model/course/CourseMapOrganizationLogoModel";
import { PostType } from "@/enum/PostType";
import MyCourseCardModel from "@/service/model/course/MyCourseCardModel";
import WishCourseModel from "@/service/model/course/WishCourseModel";

class CourseService extends ApiService {
  /**
   * 코스 상세
   * @param id
   * @param withLecturer
   */
  public retrieveCourse(
    id: number,
    withLecturer?: boolean
  ): Observable<CourseModel> {
    return this.get<Course>("/course", { id, withLecturer }).pipe(
      map((course: Course) => CourseModel.create(course)),
      catchError(() => of(new CourseModel()))
    );
  }

  /**
   * 코스 리스트
   * @param id
   */
  public retrieveRecommendationCourseList(id: number): Observable<Course[]> {
    return this.get<Course[]>("/course/recommendation/list", { id }).pipe(
      map((courseList: Course[]) =>
        courseList.map((course: Course) => CourseModel.create(course))
      ),
      catchError(() => of([]))
    );
  }

  /**
   * 프로모션 리스트: new, best, recommended 코스 리스트
   * @param type
   * @param organizationId
   */
  public retrieveCoursePromotionList(
    type: CoursePromotionType,
    organizationId?: number
  ): Observable<CoursePromotion[]> {
    return this.get<CoursePromotion[]>("/course/list/promotion", {
      type,
      organizationId
    }).pipe(
      map((coursePromotions: CoursePromotion[]) =>
        coursePromotions.map(coursePromotions =>
          CoursePromotionsModel.create(coursePromotions)
        )
      ),
      catchError(() => of([]))
    );
  }

  /**
   * course 등록 여부 확인
   * @param courseId
   */
  public retrieveIsWishCourse(courseId: number): Observable<boolean> {
    return this.get<boolean>("/course/myWish", { courseId }, true).pipe(
      map((isWishCourse: boolean) => isWishCourse),
      catchError(() => of(false))
    );
  }

  /**
   * wish course 추가
   * @param courseId
   */
  public createWishCourse(courseId: number): Observable<WishCourse> {
    return this.post<WishCourse>("/course/myWish", { courseId }, true).pipe(
      map((wishCourse: WishCourse) => WishCourseModel.create(wishCourse)),
      catchError(() => of(new WishCourseModel()))
    );
  }

  /**
   * wish course 추가
   * @param courseId
   */
  public deleteWishCourse(courseId: number): Observable<boolean> {
    return this.delete<boolean>("/course/myWish", { courseId }, true).pipe(
      map((isSuccess: boolean) => isSuccess),
      catchError(() => of(false))
    );
  }

  /**
   * course id 조회
   * @param lectureId
   */
  public retrieveCourseId(lectureId: number): Observable<number> {
    return this.get<number>("/course/id", { lectureId }).pipe(
      map((courseId: number) => courseId),
      catchError(() => of(0))
    );
  }

  /**
   * wish 코스 리스트 조회
   */
  public retrieveWishCourseList(): Observable<WishCourse[]> {
    return this.get<WishCourse[]>("/course/myWish/list", {}, true).pipe(
      map((myWishCourseCardList: WishCourse[]) =>
        myWishCourseCardList.map(myWishCourseCard =>
          WishCourseModel.create(myWishCourseCard)
        )
      ),
      catchError(() => of([]))
    );
  }

  /**
   * 코스 기업 로고 리스트 조회
   * @param courseId
   */
  public retrieveCourseMapOrganizationLogo(
    courseId: number
  ): Observable<CourseMapOrganizationLogo[]> {
    return this.get<CourseMapOrganizationLogo[]>(
      "/course/organizationLogo/list",
      { courseId }
    ).pipe(
      map((logoList: CourseMapOrganizationLogo[]) =>
        logoList.map(logo => CourseMapOrganizationLogoModel.create(logo))
      ),
      catchError(() => of([]))
    );
  }

  /**
   * Post 수가 포함된 진행 중인 내 강의 리스트
   */
  public retrieveMyLectureCardListWithPostCount(
    postType: PostType
  ): Observable<MyCourseCard[]> {
    return this.get<MyCourseCard[]>(
      "/course/card/list/postCount",
      { postType },
      true
    ).pipe(
      map((myCourseCardList: MyCourseCard[]) =>
        myCourseCardList.map(myCourseCard =>
          MyCourseCardModel.create(myCourseCard)
        )
      ),
      catchError(() => of([]))
    );
  }

  /**
   * Bookmark 수가 포함된 진행 중인 내 강의 리스트
   */
  public retrieveMyLectureCardListWithBookmarkCount(): Observable<
    MyCourseCard[]
  > {
    return this.get<MyCourseCard[]>(
      "/course/card/list/bookmarkCount",
      {},
      true
    ).pipe(
      map((myCourseCardList: MyCourseCard[]) =>
        myCourseCardList.map(myCourseCard =>
          MyCourseCardModel.create(myCourseCard)
        )
      ),
      catchError(() => of([]))
    );
  }

  /**
   * Question/Answer 수가 포함된 진행 중인 강의 리스트 [강사]
   */
  public retrieveLecturerMyCourseCardList(): Observable<MyCourseCard[]> {
    return this.get<MyCourseCard[]>(
      "/course/card/list/postCount/lecturer",
      {},
      true
    ).pipe(
      map((myCourseCardList: MyCourseCard[]) =>
        myCourseCardList.map(myCourseCard =>
          MyCourseCardModel.create(myCourseCard)
        )
      ),
      catchError(() => of([]))
    );
  }
}

export const courseService = new CourseService();
