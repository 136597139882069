import { createModel } from "@/util/ModelUtil";
import PageableModel from "@/service/model/page/PageableModel";

export default class PageModel<T> {
  constructor(
    public pageableDTO: Pageable = new PageableModel(),
    public pagingData: Array<T> = []
  ) {}

  public static create<T>(pageModel?: Page<T>): PageModel<T> {
    return createModel<PageModel<T>>(PageModel, pageModel);
  }
}
