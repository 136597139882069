import { createModel } from "@/util/ModelUtil";
import { SubscribeType } from "@/enum/SubscribeType";

export default class SubscribeGroupModel {
  constructor(
    public subscribeType: SubscribeType = SubscribeType.BASIC,
    public subscribeList: Subscribe[] = [],
    public courseCardList: CourseCard[] = [],
    public courseCount: number = 0
  ) {}

  public static create(subscribeGroup?: SubscribeGroup): SubscribeGroupModel {
    return createModel<SubscribeGroupModel>(
      SubscribeGroupModel,
      subscribeGroup
    );
  }
}
