import { Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ namespaced: true, name: "course" })
export default class Course extends VuexModule {
  private courseCount = 0;

  @Mutation
  public setCount(count: number): void {
    this.courseCount = count;
  }

  public get count(): number {
    return this.courseCount;
  }
}
