import { createModel } from "@/util/ModelUtil";
import { UserType } from "@/enum/UserType";

export default class ReplyModel {
  constructor(
    public id: number = 0,
    public userId: number = 0,
    public commentId: number = 0,
    public content: string = "",
    public exposureFlag: boolean = false,
    public createdAt: number = new Date().getTime(),
    public updatedAt: number = new Date().getTime(),
    public userNickName: string = "",
    public userProfileImageUrl: string = "",
    public userType: UserType = UserType.NORMAL,
    public isLikeReply: boolean = false,
    public likeCount: number = 0
  ) {}

  public static create(reply?: Reply): ReplyModel {
    return createModel<ReplyModel>(ReplyModel, reply);
  }
}
