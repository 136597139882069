const isEmptyString = (str: string | undefined | null): boolean => {
  if (!str) {
    return true;
  }
  if (str.trim().length === 0) {
    return true;
  }
  return false;
};

export { isEmptyString };
