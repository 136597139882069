export enum UserType {
  S_ADMIN = "S_ADMIN", // 최고 관리자
  ADMIN = "ADMIN", //일반 관리자
  ORG = "ORG", // 기업 회원
  NORMAL = "NORMAL", // 일반 회원
  LECTURER = "LECTURER", // 강사
  ORG_MANAGER = "ORG_MANAGER", // 기업 관리자
  OPERATOR = "OPERATOR",
  K_DIGITAL_CREDIT = "K_DIGITAL_CREDIT"
}
