import { createModel } from "@/util/ModelUtil";
import { CommunityCategoryType } from "@/enum/community/CommunityCategoryType";

export default class CategoryModel {
  constructor(
    public id: number = 0,
    public organizationId: number = 0,
    public categoryType: CommunityCategoryType = CommunityCategoryType.FREE_QNA,
    public order: number = 0,
    public color: string = "",
    public bgColor: string = "",
    public title: string = ""
  ) {}

  public static create(category?: Category): CategoryModel {
    return createModel<CategoryModel>(CategoryModel, category);
  }
}
