import { menu } from "@/router/menu";
import { HeaderClassType } from "@/enum/HeaderClassType";
import { LayoutType } from "@/enum/LayoutType";
import { RouteConfig } from "vue-router";
import RouterMeta from "@/router/model/RouterMeta";
import { RouterMenuName } from "@/enum/RouterMenuName";

const meta: RouterMeta = {
  headerClass: `${HeaderClassType.Clearfix}`,
  layout: LayoutType.NoneLayout,
  guard: {
    login: true
  }
};

export const lecturePage: RouteConfig[] = [
  {
    path: "/lecture/:lectureId/:subChapterId",
    name: RouterMenuName.LectureMain,
    component: menu.lecture.main,
    meta
  },
  {
    path: "/lecture/codeEditor",
    name: RouterMenuName.LectureCodeEditor,
    component: menu.lecture.codeEditor,
    meta
  },
  {
    path: "/lecture/editor",
    name: RouterMenuName.LectureEditor,
    component: menu.lecture.editor,
    meta
  }
];
