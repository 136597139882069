import { Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ namespaced: true, name: "theme" })
export default class Theme extends VuexModule {
  public isShowMobileNavigation = false;

  @Mutation
  public setIsShowMobileNavigation(isShowMobileNavigation: boolean): void {
    this.isShowMobileNavigation = isShowMobileNavigation;
  }
}
