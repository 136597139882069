import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import LectureModel from "@/service/model/lecture/LectureModel";
import MyLectureModel from "@/service/model/myLecture/MyLectureModel";
import { lectureService } from "@/service/api/LectureService";
import { myLectureService } from "@/service/api/MyLectureService";
import { zip } from "rxjs";

@Module({ namespaced: true, name: "lectureCategory" })
export default class LectureCategory extends VuexModule {
  public lecture: Lecture = new LectureModel();
  public myLecture: MyLecture = new MyLectureModel();
  public subChapterList: SubChapter[] = [];

  @Mutation
  public destroy(): void {
    this.lecture = new LectureModel();
    this.myLecture = new MyLectureModel();
    this.subChapterList = [];
  }

  @Mutation
  public setLectureAnsSubChapterList(lecture: Lecture): void {
    this.lecture = lecture;

    this.subChapterList = [];
    lecture.chapterList.forEach((chapter: Chapter) => {
      this.subChapterList.push(...chapter.subChapterList);
    });
  }

  @Mutation
  public setMyLecture(myLecture: MyLecture): void {
    this.myLecture = myLecture;
  }

  @Action
  public getLectureCategoryByLectureId(lectureId: number): void {
    if (lectureId !== 0 && this.lecture.id !== lectureId) {
      zip(
        lectureService.retrieveLecture(lectureId),
        myLectureService.retrieveMyLecture(lectureId)
      ).subscribe(([lecture, myLecture]: [Lecture, MyLecture]) => {
        this.context.commit("setLectureAnsSubChapterList", lecture);
        this.context.commit("setMyLecture", myLecture);
      });
    }
  }

  @Action
  public getMyLectureByLectureId(lectureId: number): void {
    myLectureService
      .retrieveMyLecture(lectureId)
      .subscribe((myLecture: MyLecture) => {
        this.context.commit("setMyLecture", myLecture);
      });
  }
}
