import { LectureType } from "@/enum/LectureType";

export default function(type: LectureType): string {
  let lecture = "";
  switch (type) {
    case LectureType.ELearning:
      lecture = "이러닝";
      break;
    case LectureType.FlipLearning:
      lecture = "플립러닝";
      break;
    case LectureType.LiveLearning:
      lecture = "라이브";
      break;
    case LectureType.SPECIAL_TRACK:
      lecture = "스페셜 트랙";
      break;
  }
  return lecture;
}
