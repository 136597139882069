import { RouterMenuName } from "@/enum/RouterMenuName";
import { menu } from "@/router/menu";
import { HeaderClassType } from "@/enum/HeaderClassType";
import { LayoutType } from "@/enum/LayoutType";
import { RouteConfig } from "vue-router";

export const specialTrackPage: RouteConfig[] = [
  {
    path: "/specialTrack",
    name: RouterMenuName.SpecialTrack,
    component: menu.specialTrack.main,
    meta: {
      headerClass: `${HeaderClassType.My}`,
      layout: LayoutType.FormLayout
    }
  },
  {
    path: "/specialTrack/:specialTrackId",
    name: RouterMenuName.SpecialTrackDetail,
    component: menu.specialTrack.detail,
    meta: {
      headerClass: `${HeaderClassType.My}`,
      layout: LayoutType.FormLayout
    }
  },
  {
    path: "/specialTrack/apply/complete",
    name: RouterMenuName.SpecialTrackApplyComplete,
    component: menu.specialTrack.apply.complete,
    meta: {
      headerClass: `${HeaderClassType.My}`,
      layout: LayoutType.FormLayout,
      guard: {
        login: true
      }
    }
  },
  {
    path: "/specialTrack/apply/:specialTrackId/:curriculumId",
    name: RouterMenuName.SpecialTrackApply,
    component: menu.specialTrack.apply.main,
    meta: {
      headerClass: `${HeaderClassType.My}`,
      layout: LayoutType.FormLayout,
      guard: {
        login: true
      }
    }
  }
];
