import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import AlertModel from "@/components/common/modal/model/AlertModel";

@Module({ namespaced: true, name: "alert" })
export default class Alert extends VuexModule {
  public isAutoClose = true;
  public isOpen = false;
  public model: AlertModel = this.emptyAlert();

  @Mutation
  public open(alert: AlertModel): void {
    this.model = alert;
    this.isOpen = true;
  }

  @Mutation
  public close(): void {
    this.isOpen = false;
  }

  @Mutation
  public setIsAutoClose(isAutoClose: boolean): void {
    this.isAutoClose = isAutoClose;
  }

  public emptyAlert(): AlertModel {
    return {
      title: "",
      content: "",
      text: "",
      addText: ""
    };
  }
}
