import { createModel } from "@/util/ModelUtil";
import SubscriberModel from "@/service/model/subscribe/SubscriberModel";
import SubscribeModel from "@/service/model/subscribe/SubscribeModel";
import PaymentHistoryModel from "@/service/model/paymentHistory/PaymentHistoryModel";
import CouponMapUserModel from "@/service/model/coupon/CouponMapUserModel";

export default class SubscribeCardModel {
  constructor(
    public subscribe: Subscribe = new SubscribeModel(),
    public changeSubscribe: Subscribe = new SubscribeModel(),
    public paymentHistory: PaymentHistory = new PaymentHistoryModel(),
    public subscriber: Subscriber = new SubscriberModel(),
    public userMapCoupon: CouponMapUser = new CouponMapUserModel()
  ) {}

  public static create(subscribeCard?: SubscribeCard): SubscribeCardModel {
    return createModel<SubscribeCardModel>(SubscribeCardModel, subscribeCard);
  }
}
